import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  children: any;
  style?:any;
}
const Label = styled.p`
position: absolute;
line-height: 16px;
top: -23px;
`
const Labeled = (props: Props) => {
  const { label, children, style={}} = props;

  return (
    <div style={{position:'relative',...style}}>
      <Label>{label}</Label> {children}
    </div>
  );
};
export default Labeled;
