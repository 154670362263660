import React from "react";
import styled from "styled-components";

interface Props {
  visible:boolean;
}


const SpinnerBackground = styled.div<Visible>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background: rgba(128, 128, 128, 0.8);
    justify-content: center;
    align-items: center;
    display: ${(props) => (props.open ? "flex" : "none")};
`
const Spinner = styled.div`
    border: 16px solid #f3f3f3;
    border-top: 16px solid #f6a335;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
`

interface Visible {
    open: boolean;

}

const Loader = (props: Props) => {
  const { visible } = props;

  return (
    <SpinnerBackground open={visible}>
      <Spinner></Spinner>
    </SpinnerBackground>
  );
};
export default Loader;
