import styled from "styled-components";
import { Button, Form, Row } from "react-bootstrap";

export const CustomInput = styled(Form.Control)`
  border: 1px solid #404040;
  border-radius: 10px;
  opacity: 1;
  height: 36px;
  min-width: 240px;
  text-align: left;
  font: normal normal normal 12px/28px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;

  &:focus {
    color: #000000;
  }

  @media only screen and (min-width: 576px) {
    font: normal normal normal 16px/28px Montserrat;
    height: 58px;
  }
`;

export const CustomCheckBoxLabel = styled(Form.Check.Label)`
  text-align: left;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;

  @media only screen and (min-width: 576px) {
    font: normal normal normal 18px/28px Montserrat;
  }
`;
export const CustomCheckBoxInput = styled(Form.Check.Input)`
  width: 23px;
  height: 23px;
  border: 2px solid #404040;
  opacity: 1;
`;
export const CustomCheck = styled(Form.Check)`
  min-width: 240px;
`;

export const CustomButton = styled(Button)<CustomButtonProps>`
  background: ${(props) =>
    props.$valid ? "#f6a335" : "#f6a335"} 0% 0% no-repeat padding-box; // 404040
  border-radius: 15px;
  width: 60%;
  opacity: 1;
  &:hover {
    color: #fff;
    background-color: #f6a335;
    border-color: #fff;
  }
  &:active {
    color: #fff !important;
    background-color: #f6a335 !important;
    border-color: #fff !important;
    box-shadow: none !important;
  }
  &:focus {
    color: #fff !important;
    background-color: #f6a335 !important;
    border-color: #fff !important;
    box-shadow: none !important;
  }
  &:disabled {
    color: #fff !important;
    background-color: #404040 !important;
    border-color: #fff !important;
    box-shadow: none !important;
  }
`;

interface CustomButtonProps {
  valid: boolean;
}

export const Description = styled(CustomCheckBoxLabel)`
  font: normal normal normal 12px/12px Montserrat;

  @media only screen and (min-width: 576px) {
    font: normal normal normal 14px/16px Montserrat;
  }
`;

export const CustomTextAreaInput = styled(CustomInput)`
  height: 150px;
  width: 100%;
  padding: 0.375rem 0.75rem;
`;

export const Extend = styled.a`
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`;
export const SortTitle = styled.p`
  font: normal normal normal 16px/16px Montserrat;
  letter-spacing: 0px;
  color: #404040;
`;

export const SortDescription = styled.p`
  font: italic normal normal 12px/16px Montserrat;
  letter-spacing: 0px;
  color: #404040;
`;

export const FilterRow = styled(Row)`
  height: auto;
  @media only screen and (min-width: 992px) {
    height: 36px;
  }
  
`;

export const FormWrapper = styled.div`
  min-width: 400px;
`;