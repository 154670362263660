import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Col, Row, Table } from "react-bootstrap";
import { increaseOfferPopularity } from "src/scripts/services/leasing.resource";
import { scrollToTop } from "src/scripts/misc/utils";
import { LeasingOffer } from "../../../models/LeasingOffer";

var origin = window.location.origin;
const includedIcons = {
  yes: origin + "/static/dist/public/assets/icons/minus.svg",
  no: origin + "/static/dist/public/assets/icons/icon-review-accepted.svg",
};

interface Props {
  leasingOffers: LeasingOffer[];
  selectedLeasingOffer: LeasingOffer | undefined;
  setSelectedLeasingOffer: (leasingOffer: LeasingOffer) => void;
}

const LeasingOfferBrowser = (props: Props) => {
  const { leasingOffers, selectedLeasingOffer, setSelectedLeasingOffer } = props;

  const increasePopularity = async (offer: LeasingOffer) => {
    await increaseOfferPopularity(offer.id);
  };

  let newDate = new Date();
  let date = newDate.getDate();
  newDate.setMonth(newDate.getMonth()+8)
  let month = newDate.getMonth();
  let year = newDate.getFullYear();
  let separator = '-'
  let priceValidUntil = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`

  return (
    <div>
      <h3 style={{ fontSize: "large" }}>Inne warianty oferty dla tego modelu</h3>
      <Table responsive striped bordered hover size="sm">
        <thead>
        <tr>
          <th>#</th>
          <th>Rata netto</th>
          <th>Okres kontraktu</th>
          <th>Przebieg roczny</th>
          <th>Wkład własny</th>
          <th>Opony</th>
          <th>Ubezpieczenie</th>
          <th>Assistance</th>
          <th>Serwis</th>
        </tr>
        </thead>
        <tbody>

      {leasingOffers.map(offer => {
        if (selectedLeasingOffer?.id !== offer.id)
        return (
            <tr key={offer.id} onClick={() => {setSelectedLeasingOffer(offer); increasePopularity(offer); scrollToTop()}}>
              <Helmet>
                <script type="application/ld+json">
                  {`{
                    "@context": "https://schema.org",
                    "@type": ["Product", "Car"],
                    "sku": "${offer?.id}",
                    "brand": "${offer.car.brand.name}",
                    "name": "Wynajem długoterminowy ${offer.car.brand.name} ${offer.car.model} za ${offer?.net_installment} zł",
                    "alternateName": "Auto na abonament ${offer.car.brand.name} ${offer.car.model} za ${offer?.net_installment} zł",
                    "image" : "https://www.poznajdealera.pl/${offer?.car?.avatar}/",
                    "description": "Wynajem długoterminowy ${offer.car.brand.name} ${offer.car.model} ${offer.car.specification},
                     wartość samochodu: ${offer?.value} zł,
                     miesięczna rata netto: ${offer?.net_installment} zł,
                     miesięczna rata brutto: ${offer?.gross_installment} zł,
                     okres wynajmu: ${offer?.period} miesięcy,
                     wpłata wstępna: ${offer?.self_deposit}%,
                     limit roczny: ${offer?.mileage_limit} km.",
                    "offers": {
                      "@type": "Offer",
                      "url": "https://www.poznajdealera.pl/finansowanie/wynajem/offer/${offer.id}/",
                      "areaServed": "PL",
                      "itemCondition": "https://schema.org/NewCondition",
                      "availability": "https://schema.org/InStock",
                      "businessFunction": "LeaseOut",
                      "leaseLength": {
                        "@type": "QuantitativeValue",
                        "value": ${offer?.period},
                        "unitText": "miesięcy"
                      },
                      "price" : "${offer?.net_installment}",
                      "priceValidUntil": "${priceValidUntil}",
                      "priceCurrency" : "PLN",
                      "name": "Wynajem długoterminowy ${offer.car.brand.name} ${offer.car.model} za ${offer?.net_installment} zł",
                      "alternateName": "Auto na abonament ${offer.car.brand.name} ${offer.car.model} za ${offer?.net_installment} zł",
                      "description": "Wynajem długoterminowy ${offer.car.brand.name} ${offer.car.model} ${offer.car.specification},
                       wartość samochodu: ${offer?.value} zł,
                       miesięczna rata netto: ${offer?.net_installment} zł,
                       miesięczna rata brutto: ${offer?.gross_installment} zł,
                       okres wynajmu: ${offer?.period} miesięcy,
                       wpłata wstępna: ${offer?.self_deposit}%,
                       limit roczny: ${offer?.mileage_limit} km.",
                       "image" : "https://www.poznajdealera.pl/${offer?.car?.avatar}/"
                    },
                    "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "5.0",
                      "reviewCount": "1"
                    },
                    "review": [
                      {
                        "@type": "Review",
                        "author": {
                          "@type": "Person",
                          "name": "Piotr"
                        },
                        "datePublished": "2022-08-10",
                        "reviewBody": "Oferta wygląda na interesującą. Myślę, że warto poprosić o kontakt i uzyskać więcej szczegółów.",
                        "name": "Oferta od importera",
                        "reviewRating": {
                          "@type": "Rating",
                          "bestRating": "5",
                          "ratingValue": "5",
                          "worstRating": "1"
                        }
                      }
                    ]
                  }`}
                </script>
              </Helmet>
              <td style={{verticalAlign: "middle"}}>{offer.id}</td>
              <td style={{verticalAlign: "middle"}}>{offer.net_installment} zł/mc</td>
              <td style={{verticalAlign: "middle"}}>{offer.period} mc</td>
              <td style={{verticalAlign: "middle"}}>{offer.mileage_limit} km</td>
              <td style={{verticalAlign: "middle"}}>{offer.self_deposit}%</td>
              <td style={{verticalAlign: "middle"}}>
                <img className="mx-auto d-block" src={offer.tires_included ? includedIcons.no : includedIcons.yes} alt="status" />
              </td>
              <td style={{verticalAlign: "middle"}}>
                <img className="mx-auto d-block" src={offer.insurance_included ? includedIcons.no : includedIcons.yes} alt="status" />
              </td>
              <td style={{verticalAlign: "middle"}}>
                <img className="mx-auto d-block" src={offer.assistance_included ? includedIcons.no : includedIcons.yes} alt="status" />
              </td>
              <td style={{verticalAlign: "middle"}}>
                <img className="mx-auto d-block" src={offer.service_included ? includedIcons.no : includedIcons.yes} alt="status" />
              </td>
            </tr>
        )
      })}

        </tbody>
      </Table>
    </div>
  );
};

export default LeasingOfferBrowser;
