//@ts-nocheck
import suv_unselected from "@/car-bodies/unselected/suv.svg";
import cabrio_unselected from "@/car-bodies/unselected/cabriolet.svg";
import coupe_unselected from "@/car-bodies/unselected/coupe.svg";
import crossover_unselected from "@/car-bodies/unselected/crossover.svg";
import dostawcze_unselected from "@/car-bodies/unselected/dostawcze.svg";
import hatchback_unselected from "@/car-bodies/unselected/hatchback.svg";
import kombi_unselected from "@/car-bodies/unselected/kombi.svg";
import sedan_unselected from "@/car-bodies/unselected/sedan.svg";
import van_unselected from "@/car-bodies/unselected/van.svg";
import pickup_unselected from "@/car-bodies/unselected/pickup.svg";

import suv from "@/car-bodies/selected/suv.svg";
import cabrio from "@/car-bodies/selected/cabriolet.svg";
import coupe from "@/car-bodies/selected/coupe.svg";
import crossover from "@/car-bodies/selected/crossover.svg";
import dostawcze from "@/car-bodies/selected/dostawcze.svg";
import hatchback from "@/car-bodies/selected/hatchback.svg";
import kombi from "@/car-bodies/selected/kombi.svg";
import sedan from "@/car-bodies/selected/sedan.svg";
import van from "@/car-bodies/selected/van.svg";
import pickup from "@/car-bodies/selected/pickup.svg";

export interface CarBodyType {
    image:any;
    label:string;
    slug:string;
}
export interface CarBodyObject {
    [key:string]:CarBodyType
}

export const carBodiesUnselected:CarBodyObject = {
  suv: {
    image: suv_unselected,
    label: "SUV",
    slug:'suv'
  },
  cabrio: {
    image: cabrio_unselected,
    label: "Cabriolet",
    slug:'cabrio'
  },
  coupe: {
    image: coupe_unselected,
    label: "Coupe",
    slug:'coupe'
  },
  crossover: {
    image: crossover_unselected,
    label: "Crossover",
    slug:'crossover'
  },
  dostawcze: {
    image: dostawcze_unselected,
    label: "Dostawcze",
    slug:'dostawcze'
  },
  hatchback: {
    image: hatchback_unselected,
    label: "Hatchback",
    slug:'hatchback'
  },
  kombi: {
    image: kombi_unselected,
    label: "Kombi",
    slug:'kombi'
  },
  sedan: {
    image: sedan_unselected,
    label: "Sedan",
    slug:'sedan'
  },
  van: {
    image: van_unselected,
    label: "Van",
    slug:'van'
  },
  pickup: {
    image: pickup_unselected,
    label: "Pickup",
    slug:'pickup'
  },
};
export const carBodiesSelected:CarBodyObject = {
    suv: {
        image: suv,
        label: "SUV",
        slug:'suv'
      },
      cabrio: {
        image: cabrio,
        label: "Cabriolet",
        slug:'cabrio'
      },
      coupe: {
        image: coupe,
        label: "Coupe",
        slug:'coupe'
      },
      crossover: {
        image: crossover,
        label: "Crossover",
        slug:'crossover'
      },
      dostawcze: {
        image: dostawcze,
        label: "Dostawcze",
        slug:'dostawcze'
      },
      hatchback: {
        image: hatchback,
        label: "Hatchback",
        slug:'hatchback'
      },
      kombi: {
        image: kombi,
        label: "Kombi",
        slug:'kombi'
      },
      sedan: {
        image: sedan,
        label: "Sedan",
        slug:'sedan'
      },
      van: {
        image: van,
        label: "Van",
        slug:'van'
      },
      pickup: {
        image: pickup,
        label: "Pickup",
        slug:'pickup'
      },
};
