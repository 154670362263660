export interface SelectItem {
    id: any;
    value: string;
    name: string;
}

export const FilterOptions: SelectItem[] = [
    { id: 0, name: "Najlepiej oceniane", value: "average__rating" },
    { id: 1, name: "Od A do Z", value: "display_name" },
    { id: 2, name: "Największa liczba ocen", value: "opinions__count" },
    { id: 3, name: "Największy % rekomendacji", value: "recommended__percentage" },
];