import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Wrapper } from "./Styled";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { imagePlaceholder } from "src/scripts/enums/defaults";
import useMobileRecognizer, {
  ScreenBreakpoints,
} from "src/scripts/hooks/useMobileRecognizer";
interface Props {
  images?: { image: string }[];
  loading?: boolean;
}
const LGalleryImage = styled.a`
  border-radius: 15px;
  ${(props) => {
    if (props.href) {
      return `background-image: url(${props.href})`;
    }
  }};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 500px;
  @media screen and (max-width: 991px) {
    height: 400px;
  }
  @media only screen and (max-width: 767px) {
    height: 300px;
  }
  @media screen and (max-width: 400px) {
    height: 200px;
  }
`;
const Dot = styled.div<{ active: boolean }>`
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin: 3px;
  border-style: solid;
  border-width: 2px;
  background-color: ${(props) => (props.active ? "#f6a335" : "#ffffff7f")};
  border-color: #f6a335;
`;
const DotContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;
const ColWithHiddenScroll = styled(Col)`
  &::-webkit-scrollbar {
    display: none;
  }
`;
const SmallImage = styled.img`
  border-radius: 15px;
  width: 100%;
  object-fit: cover;
  height: 92px;
`;
const ChevronButtonLeft = styled.button`
  mix-blend-mode: difference;
  position: absolute;
  width: 20%;
  height: 100%;
  left: 15px;
  @media only screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;
const ChevronButtonRight = styled(ChevronButtonLeft)`
  left:calc(80% - 15px);
  transform: rotate(180deg);
`;

const ChevronImage = styled.img`
  @media only screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
`;
const StyledCol = styled(Col)`
  position: relative;
  border-radius: 15px;
  height: 500px;
  @media screen and (max-width: 991px) {
    height: 400px;
  }
  @media only screen and (max-width: 767px) {
    height: 300px;
  }
  @media screen and (max-width: 400px) {
    height: 200px;
  }
`;
const LeasingOfferGallery = (props: Props) => {
  const { images, loading } = props;
  const [imageChoices, setImageChoices] =
    useState<{ image: string; id: number }[]>();
  const [currentImage, setCurrentImage] =
    useState<{ image: string; id: number }>();
  useEffect(() => {
    if (images && images.length > 0) {
      let imagesToSet = [...images];
      // imagesToSet.
      const newChoices = imagesToSet.map((item, index) => ({
        image: item.image,
        id: index,
      }));
      newChoices.splice(0, 1);
      setImageChoices(newChoices);
    } else if (!loading && (!images || images.length === 0)) {
      let toSet = [];
      for (let i = 1; i < 6; i++) {
        toSet.push({
          image: imagePlaceholder,
          id: i,
        });
      }
      setImageChoices(toSet);
    }
  }, [images, loading]);
  useEffect(() => {
    if (images && images.length > 0) {
      setCurrentImage({
        image: images[0].image,
        id: 0,
      });
    } else if (!loading && (images == undefined || images.length === 0)) {
      setCurrentImage({
        image: imagePlaceholder,
        id: 0,
      });
    }
  }, [images, loading]);
  const isMobile = useMobileRecognizer(ScreenBreakpoints.MEDIUM);
  return (
    <Wrapper>
      <Helmet>
        <script>{`
          lightbox.option({
            'resizeDuration': 200,
            'wrapAround': true,
            'albumLabel': "Zdjęcie %1 z %2",
            'disableScrolling': true,
            'imageFadeDuration': 200,
            'alwaysShowNavOnTouchDevices': true
          })
          `}
        </script>
      </Helmet>
      <Row className="justify-content-center">
        <StyledCol
          className="d-flex justify-content-center flex-column flex-lg-row"
        >
          <LGalleryImage
            href={currentImage?.image}
            data-lightbox="gallery"
          ></LGalleryImage>
          {imageChoices && imageChoices?.length > 0 && (
            <>
              {" "}
              <ChevronButtonLeft
                onClick={() => {
                  if ( currentImage && images && images.length > 1) {
                    const newChoices = [...imageChoices];
                    newChoices.splice(currentImage!.id, 0, currentImage!);
                    let nextIndex = newChoices.findIndex(
                      (item) => item.image === currentImage.image
                    ) -1;
                    nextIndex = nextIndex <0 ? newChoices.length-1 : nextIndex;
                    const newCurrent = newChoices[nextIndex];
                    newChoices.splice(nextIndex, 1);
                    setCurrentImage(newCurrent);
                    setImageChoices(newChoices);
                  }
                }}
              >
                <ChevronImage
                  src="/static/dist/public/assets/singleDealer/icon-arrow-slider.svg"
                  alt="strzałka w lewo"
                />
              </ChevronButtonLeft>
              <ChevronButtonRight
                onClick={() => {
                    if ( currentImage && images && images.length > 1) {
                      const newChoices = [...imageChoices];
                      newChoices.splice(currentImage!.id, 0, currentImage!);
                      let nextIndex = newChoices.findIndex(
                        (item) => item.image === currentImage.image
                      ) + 1;
                      nextIndex = nextIndex === newChoices.length ? 0 : nextIndex;
                      const newCurrent = newChoices[nextIndex];
                      newChoices.splice(nextIndex, 1);
                      setCurrentImage(newCurrent);
                      setImageChoices(newChoices);
                    }
                }}
              >
                <ChevronImage
                  src="/static/dist/public/assets/singleDealer/icon-arrow-slider.svg"
                  alt="strzałka w prawo"
                />
              </ChevronButtonRight>
            </>
          )}
          <DotContainer className="d-flex d-sm-flex d-md-flex d-lg-none justify-content-center">
            {images?.map((item, index) => (
              <Dot
                key={item.image}
                active={currentImage?.id === index}
                onClick={() => {
                  if (imageChoices) {
                    const newChoices = [...imageChoices];
                    // return taken image
                    newChoices.splice(currentImage!.id, 0, currentImage!);
                    // find clicked image
                    const _index = newChoices.findIndex((image) => {
                      return image.image === item.image;
                    });
                    // pick clicked image
                    const newCurrent = newChoices[_index];
                    if (currentImage?.image !== newCurrent.image) {
                      // remove clicked image from list
                      newChoices.splice(_index, 1);
                      setCurrentImage(newCurrent);
                      setImageChoices(newChoices);
                    }
                  }
                }}
              ></Dot>
            ))}
          </DotContainer>
        </StyledCol>

        <ColWithHiddenScroll
          sm="3"
          style={{ maxHeight: 485 }}
          className="d-none	d-sm-none d-md-none d-lg-flex flex-column"
        >
          {imageChoices?.map((item, index) => (
          <a href={ item.image }
            data-lightbox="gallery"
            onClick={(e) => {
                e.preventDefault()
              }}
            >
            <SmallImage
              className="my-1"
              key={item.id}
              src={item.image}
              onClick={() => {
                const newChoices = [...imageChoices];
                newChoices.splice(index, 1, currentImage!);
                setImageChoices(newChoices);
                setCurrentImage(item);
              }}
            />
          </a>
          ))}
        </ColWithHiddenScroll>
      </Row>
    </Wrapper>
  );
};

export default LeasingOfferGallery;
