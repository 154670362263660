import React, { useEffect, useState } from "react";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import {
  carBodiesSelected,
  carBodiesUnselected,
  CarBodyType,
} from "src/scripts/enums/car-bodies";
import { BodyType } from "src/scripts/models/BodyType";
import styled from "styled-components";
interface Props {
  carBodyType: BodyType[];
  selectedBodyTypes: BodyType[];
  setSelectedBodyTypes: (type: BodyType[]) => void;
  carBodyPickerState: boolean;
  setCarBodyPickerState: (type: boolean) => void;
}
interface ButtonProps {
  open: boolean;
}

export const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${(props) =>
    props.open ? "#5a6268" : "#0062aa"} !important;
  box-shadow: none !important;
`;

const CarBodyPicker = (props: Props) => {
  const { carBodyType, selectedBodyTypes,  setSelectedBodyTypes, carBodyPickerState, setCarBodyPickerState } = props;
  const [open, setOpen] = useState(false); 
  const toggleCarBody = (car:BodyType) => {
    if (!selectedBodyTypes.includes(car)) {
      setSelectedBodyTypes([...selectedBodyTypes, car]);
    } else {
      setSelectedBodyTypes(
        selectedBodyTypes.filter((selectedCar) => selectedCar !== car)
      );
    }
  };

  useEffect(() => {
    // TODO this is done twice when we click exit button or "wybierz" to close popover
    // state open is toggled twice
    // the only problem is that button for "Typ nadwozia" stays grey as open state do not correspond
    // actual state of popover
    setCarBodyPickerState(open)
  }, [open,]);

  const popover = (
    <Popover id="carbodypicker" style={{ maxWidth: "90vw" }}>
      <Popover.Header as="h3">
        Typy nadwozia
        <button
          id="popovercloseid"
          type="button"
          className="close"
          onClick={() => {
            document.body.click();
          }}>&times;</button>
      </Popover.Header>
      <Popover.Body>
        <div
          className="d-flex flex-wrap justify-content-around"
          style={{ gap: "5px" }}
        >
          {Object.values(carBodyType).map((car) => {
            return (
              <div className="d-flex align-items-center flex-column" key={car.car__body}>
                <img
                  style={{ width: "100px" }}
                  src={
                    selectedBodyTypes.includes(car)
                      ? carBodiesSelected[car.car__body]?.image
                      : carBodiesUnselected[car.car__body]?.image
                  }
                  onClick={() => toggleCarBody(car)}
                />
                <p> {carBodiesSelected[car.car__body]?.label}</p>
              </div>
            );
          })}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger="click" rootClose onToggle={() => setOpen(!open)} placement="bottom" overlay={popover}>
        <StyledButton
          variant="secondary"
          open={open}
          id="carbodypicker"
          style={{ width: "250px" }}
        >
          Wybierz
        </StyledButton>
      </OverlayTrigger>
    </>
  );
};
export default CarBodyPicker;
