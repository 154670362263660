import { AxiosResponse } from "axios";
import { Dealer } from "../models/Dealer";
import { LeasingOffer } from "../models/LeasingOffer";
import { AggregatedLeasingOffer } from "../models/AggregatedLeasingOffer";
import { Brand } from "../components/SearchLeasingForm/SearchLeasingForm";
import http from "./http";
import { getPostHeaders } from "./misc";
import { DjangoPaginatedResource } from "./types";
import { BodyType } from "../models/BodyType";


export const fetchLeasingCarBrands = async (): Promise<
  AxiosResponse<DjangoPaginatedResource<Brand[]>>
> => {
  const response = await http.get("/api/leasing-car-brands");
  return response;
};

export const fetchLeasingOfferDealers = async (
  params: any
): Promise<AxiosResponse<DjangoPaginatedResource<Dealer[]>>> => {
  const response = await http.get(`/api/dealers-list`, {params});
  return response;
};

export const postUserLeasingContactForm = async (
  data: any
): Promise<AxiosResponse<DjangoPaginatedResource<any>>> => {
  const response = await http.post(
    `/api/leasing-contact-form`,
    JSON.stringify(data),
    {
      headers: getPostHeaders(),
    }
  );
  return response;
};

export const fetchLeasingOffers = async (
  params:any
) :Promise<AxiosResponse<DjangoPaginatedResource<LeasingOffer[]>>> => {
  const response = await http.get(`/api/leasings-list`,{params});
  return response;
};

export const fetchAggregatedLeasingOffers = async (
  params:any
) :Promise<AxiosResponse<DjangoPaginatedResource<AggregatedLeasingOffer[]>>> => {
  const response = await http.get(`/api/aggregated-leasings-list`,{params});
  return response;
};

export const fetchLeasingOfferDetails = async (
  pk: number,
): Promise<AxiosResponse<LeasingOffer>> => {
  const response = await http.get(`/api/leasing-offer-view/${pk}/`);
  return response;
};

export const fetchSimilarLeasingOffers = async (
  carPk: number
): Promise<AxiosResponse<DjangoPaginatedResource<LeasingOffer[]>>> => {
  const response = await http.get(`/api/leasings-list?car_pk=${carPk}`);
  return response;
};

export const increaseOfferPopularity = async (
  offerPk: number
): Promise<AxiosResponse<LeasingOffer>> => {
  const response = await http.patch(`/api/increase-offer-popularity/${offerPk}/`, {},     {
    headers: getPostHeaders(),
  });
  return response;
};

export const fetchLeasingCarBodyTypes = async (): Promise<
  AxiosResponse<BodyType[]>
> => {
  const response = await http.get("/api/leasing-offers-body-types");
  return response;
};
