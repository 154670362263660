import styled from "styled-components";
import { Form } from "react-bootstrap";

export const CustomCheckBoxLabel = styled(Form.Check.Label)`
  text-align: left;
  font: normal normal normal 14px/30px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    font: normal normal normal 16px/30px Montserrat;
  }
`;
export const CustomCheckBoxInput = styled(Form.Check.Input)`
  width: 18px;
  height: 18px;
  border: 2px solid #404040;
  opacity: 1;
  cursor: pointer;
`;
