// TODOs
// remove double requests on initial entry
// remove unneeded code like setting offer/car count when we just change page ?
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import Slider, { createSliderWithTooltip } from "rc-slider";
import { Button, Row, Spinner, Collapse, Form } from "react-bootstrap";
import ReactDOM from "react-dom";
import CarBodyPicker from "src/scripts/components/common/CarBodyPicker";
import DropdownSelect from "src/scripts/components/common/DropdownSelect";
import Labeled from "src/scripts/components/common/Labeled";
import { Brand } from "src/scripts/components/SearchLeasingForm/SearchLeasingForm";
import OfferCard from "src/scripts/components/SearchLeasingForm/OfferCard";
import {
  fetchLeasingCarBodyTypes,
  fetchLeasingCarBrands,
  fetchLeasingOffers,
  fetchAggregatedLeasingOffers,
} from "src/scripts/services/leasing.resource";
import { LeasingOffer } from "../models/LeasingOffer";
import AggregatedOffersCard from "src/scripts/components/SearchLeasingForm/AggregatedOffersCard";
import { AggregatedLeasingOffer } from "../models/AggregatedLeasingOffer";
import { carBodiesUnselected } from "../enums/car-bodies";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";
import styled from "styled-components";
import useMobileRecognizer, {
  ScreenBreakpoints,
} from "../hooks/useMobileRecognizer";
import {
  activeDotStyle,
  budgetMarks,
  durationMarks,
  handleStyle,
  installmentMarks,
  mileageMarks,
  NET_INSTALLMENT_MAX_PRICE,
  NET_INSTALLMENT_MIN_PRICE,
  trackStyle,
} from "../static-data/SliderSettings";
import {
  CustomCheckBoxInput,
  CustomCheckBoxLabel,
} from "src/scripts/components/SearchLeasingForm/Styled";
import { BodyType } from "../models/BodyType";
import DropdownOptions from "../components/common/DropdownOptions";
interface TabProps {
  name: string;
  activeName: string;
}
interface SelectItem {
  id: any;
  value: string;
  name: string;
}
const StyledTab = styled.div<TabProps>`
  ${(props) => {
    if (props.activeName === props.name) {
      return ` color: #F6A335;
            border-style: solid;
            border-color: #F6A335;
            border-width: 0;
            border-bottom-width: 5px;`;
    }
  }}
`;

const captchaKey = process.env.CAPTCHA_KEY || "";

const Range = createSliderWithTooltip(Slider.Range);
const StyledTabText = styled.p`
  padding: 14px 30px;
  font: normal normal bold 16px/16px Montserrat;
  cursor: pointer;
`;
const StyledButton = styled(Button)`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  font-size: 16px;
  color: #0062aa;
  width: 130px;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0px 10px 20px #0000001A;

  &:hover {
    box-shadow: 0px 0px 0px transparent;
    background: #f3f3f3;
    color: #0062aa;
    border: 1px solid #0062aa;
  }
  &:focus {
    box-shadow: 0px 0px 0px transparent;
    background: #f3f3f3;
    color: #0062aa;
    border: 1px solid #0062aa;
  }
  &:active {
    border: 0 !important;
    outline: 0 !important;
  }
`;

const SearchLeasingsView = () => {
  const params = new URLSearchParams(window.location.search);
  const sortingOptions: SelectItem[] = [
    { id: 0, name: "Rata rosnąco", value: "net_installment" },
    { id: 1, name: "Rata malejąco", value: "-net_installment" },
    { id: 2, name: "Najpopularniejsze", value: "-popularity" },
  ];
  const defaultMaxPrice = params.get("max_net_installment") || "100000";
  const defaultMinPrice = params.get("min_net_installment") || "0";
  const defaultMinContractDuration = params.get("min_period") || "12";
  const defaultMaxContractDuration = params.get("max_period") || "72";
  const barndsIds =
    params
      .get("car_brand_id")
      ?.split(",")
      .filter((item) => item !== "")
      .map((item) => Number.parseInt(item)) || [];
  const defaultCarBodyTypes = params.get("car_body")?.split(",") || [];
  const defaultMinBudget = params.get("min_self_deposit") || "0";
  const defaultMaxBudget = params.get("max_self_deposit") || "30";
  const defaultMinAnnualMileage = params.get("min_mileage_limit") || "10000";
  const defaultMaxAnnualMileage = params.get("max_mileage_limit") || "40000";
  const defaultSorting = params.get("ordering") || "-popularity";

  const [fetchBodyTypes, setFetchBodyTypes] = useState(false);
  const [fetchBrands, setFetchBrands] = useState(false);

  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [priceMax, setPriceMax] = useState<number>(
    Number.parseInt(defaultMaxPrice)
  );
  const [priceMin, setPriceMin] = useState<number>(
    Number.parseInt(defaultMinPrice)
  );
  const [contractDurationMin, setContractDurationMin] = useState<number>(
    Number.parseInt(defaultMinContractDuration)
  );
  const [contractDurationMax, setContractDurationMax] = useState<number>(
    Number.parseInt(defaultMaxContractDuration)
  );
  const [startBudgetMin, setStartBudgetMin] = useState<number>(
    Number.parseInt(defaultMinBudget)
  );
  const [startBudgetMax, setStartBudgetMax] = useState<number>(
    Number.parseInt(defaultMaxBudget)
  );
  const [annualMileageMin, setAnnualMileageMin] = useState<number>(
    Number.parseInt(defaultMinAnnualMileage)
  );
  const [annualMileageMax, setAnnualMileageMax] = useState<number>(
    Number.parseInt(defaultMaxAnnualMileage)
  );
  const [carBodyType, setCarBodyType] = useState<BodyType[]>([]);
  const [selectedBodyTypes, setSelectedBodyTypes] = useState<BodyType[]>([]);

  const [offers, setOffers] = useState<LeasingOffer[]>([]);
  const [nextAvailable, setNextAvailable] = useState<boolean>(true);
  const [allOfferCount, setAllOfferCount] = useState<number>(0);

  const [aggregateOffer, setAggregateOffer] = useState<boolean>(false);
  const [aggregatedOffers, setAggregatedOffers] = useState<AggregatedLeasingOffer[]>([]);
  const [nextAggregatedPageAvailable, setNextAggregatedPageAvailable] = useState<boolean>(true);
  const [allCarCount, setAllCarCount] = useState<number>(0);

  const [orderingField, setOrderingField] = useState<SelectItem>(
    sortingOptions.find((item) => item.value === defaultSorting)!);
  const [page, setPage] = useState<number>(1);
  const [carBodyPickerState, setCarBodyPickerState] = useState<boolean>(false);
  const setSearchParams = (parmas: { [key: string]: string | number }) => {
    let searchParams = new URLSearchParams(window.location.search);
    for (let [key, value] of Object.entries(parmas)) {
      searchParams.set(key, value.toString());
    }
    var newurl =
      window.location.origin +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
  };

  const fetchDataInitial = async () => {
    const brands = fetchBrands ? selectedBrands.map((item) => item.id).join(",") :
      barndsIds.map((item) => item).join(",");

    const types = fetchBodyTypes ? selectedBodyTypes.map((item) => item.car__body).join(",") :
      defaultCarBodyTypes.join(",");

    const apiSearchParams = {
      max_net_installment: priceMax,
      min_net_installment: priceMin,
      max_period: contractDurationMax,
      min_period: contractDurationMin,
      max_self_deposit: startBudgetMax,
      min_self_deposit: startBudgetMin,
      min_mileage_limit: annualMileageMin,
      max_mileage_limit: annualMileageMax,
      car_body: types,
      car_brand_id: brands,
      page,
      ordering: orderingField.value,
    };

    const aggregateOfferInStorage = localStorage.getItem('aggregateOffer');
    if (!!aggregateOfferInStorage)
    {
      JSON.parse(aggregateOfferInStorage) == "true" ? setAggregateOffer(true) : setAggregateOffer(false)
    }
    else
    {
      window.localStorage.setItem('aggregateOffer', JSON.stringify("false"));
    }

    const response = await fetchLeasingOffers(apiSearchParams);
    setNextAvailable(response.data.next ? true : false);
    setAllOfferCount(response.data.count);
    if (response.data.results) {
      setOffers(response.data.results);
    }

    const responseAggregatedOffers = await fetchAggregatedLeasingOffers(apiSearchParams);
    setNextAggregatedPageAvailable(responseAggregatedOffers.data.next ? true : false);
    setAllCarCount(responseAggregatedOffers.data.count);
    if (responseAggregatedOffers.data.results) {
      setAggregatedOffers(responseAggregatedOffers.data.results);
    }

    setSearchParams(apiSearchParams);
    setLoading(false);
  };

  // on each parameter change we set loading to true, fetch data again and set page to 1
  useEffect(() => {
    if (loading) {
      setPage(1);
      fetchDataInitial();
    }
  }, [loading,
  ]);

  useEffect(() => {
    if (carBodyPickerState !== false) {
      return;
    }

    setLoading(true);
    setPage(1);
  }, [orderingField,
      priceMax,
      priceMin,
      contractDurationMax,
      contractDurationMin,
      startBudgetMax,
      startBudgetMin,
      annualMileageMin,
      annualMileageMax,
      carBodyPickerState,
      selectedBrands,
      aggregateOffer
  ]);

  // save info about aggregated checkbox in browser storage
  useEffect(() => {
    window.localStorage.setItem('aggregateOffer', JSON.stringify(aggregateOffer ? "true" : "false"));
  }, [aggregateOffer]);

  useEffect(() => {
    const fetchBrands = async () => {
      const response = await fetchLeasingCarBrands();
      if (response.data.results) {
        let brandsWithCounters = response.data.results
          .map((brand) => {
            return {
              ...brand,
              displayName: `${brand.name}(${brand.counter || 0})`,
            };
          })
          .filter((brand) => brand.counter > 0);
        setBrands(brandsWithCounters);
        setSelectedBrands(
          brandsWithCounters.filter((item) => barndsIds?.includes(item.id))
        );
        setFetchBrands(true)
      }
    };
    const fetchBodyTypes = async () => {
      const response = await fetchLeasingCarBodyTypes();
      if (response.data) {
        setCarBodyType(response.data);
        setSelectedBodyTypes(
          response.data.filter((body) => defaultCarBodyTypes?.includes(body.car__body))
        );
        setFetchBodyTypes(true)
      }
    };
    setLoading(true);
    fetchBrands();
    fetchBodyTypes();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const brands = selectedBrands.map((item) => item.id).join(",");
      const types = selectedBodyTypes.map((item) => item.car__body).join(",");

      // separated offers
      const response = await fetchLeasingOffers({
        max_net_installment: priceMax,
        min_net_installment: priceMin,
        max_period: contractDurationMax,
        min_period: contractDurationMin,
        max_self_deposit: startBudgetMax,
        min_self_deposit: startBudgetMin,
        min_mileage_limit: annualMileageMin,
        max_mileage_limit: annualMileageMax,
        car_body: types,
        car_brand_id: brands,
        page,
        ordering: orderingField.value,
      });
      setNextAvailable(response.data.next ? true : false);
      setAllOfferCount(response.data.count);

      if (response.data.results) {
        setOffers([...offers, ...response.data.results]);
      }
      // end of separated offers

      // aggregated offers
      if (nextAggregatedPageAvailable)
      {
          const responseAggregatedOffers = await fetchAggregatedLeasingOffers({
            max_net_installment: priceMax,
            min_net_installment: priceMin,
            max_period: contractDurationMax,
            min_period: contractDurationMin,
            max_self_deposit: startBudgetMax,
            min_self_deposit: startBudgetMin,
            min_mileage_limit: annualMileageMin,
            max_mileage_limit: annualMileageMax,
            car_body: types,
            car_brand_id: brands,
            page,
            ordering: orderingField.value,
          });
          setNextAggregatedPageAvailable(responseAggregatedOffers.data.next ? true : false);
          setAllCarCount(responseAggregatedOffers.data.count);

          if (responseAggregatedOffers.data.results) {
            setAggregatedOffers([...aggregatedOffers, ...responseAggregatedOffers.data.results]);
          }
      }
      // end of aggregated offers
    };
    if (page !== 1) {
      fetchData();
    }
  }, [page]);

  const offerCards = offers.map((item) => <OfferCard offer={item} key={item.id} />);
  const aggregatedOffersCards = aggregatedOffers.map((item) => <AggregatedOffersCard offer={item} key={item.id} />);

  const labeledStyle = {
    flexBasis: "30%",
    display: "flex",
    justifyContent: "center",
    minHeight: "50px",
    minWidth: "250px",
  };
  const dropdownStyle = {
    maxHeight: "36px",
    marginTop: "5px",
  };

  const isMobile = useMobileRecognizer(ScreenBreakpoints.SMALL);
  const [filter_open, setOpen] = useState(false);

  var canonical_url = new URL(window.location.href.split('?')[0]);

  return (
    <>
      <Helmet>
        <title>{`Wyniki wyszukiwania ofert wynajmu długoterminowego.`}</title>
        <meta name="description" content=
          {`Wyniki wyszukiwania ofert wynajmu długoterminowego.
          `}/>
        <meta property="og:description" content=
          {`Wyniki wyszukiwania ofert wynajmu długoterminowego.
          `}/>
        <link rel="canonical" href={`${canonical_url}`}/>
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="search-dealer-form-wrapper col-12 px-0 leasing--advanced-search--main-form">
            <div className="leasing--container">
              {isMobile ? (
              <>
              <StyledButton
                onClick={() => setOpen(!filter_open)}
                aria-controls="filter-collapse"
                aria-expanded={filter_open}
              >
                <img src="/static/dist/public/assets/icons/Icon-filter.svg" alt="Filtry"></img>
                Filtry
              </StyledButton>
              <Collapse in={filter_open}>
                <div id="filter-collapse">
                  <div
                    className="d-flex justify-content-center justify-content-md-between px-5 pt-4 pb-3 flex-wrap"
                    style={{ rowGap: "30px" }}
                  >
                    <Labeled label="Marka" style={labeledStyle}>
                      <DropdownSelect
                        style={dropdownStyle}
                        options={brands}
                        selected={selectedBrands}
                        setSelected={setSelectedBrands}
                        placeholder={"Marka"}
                      />
                    </Labeled>
                    <Labeled label="Rata netto" style={labeledStyle}>
                      <Range
                        dots
                        className="mt-2"
                        step={null}
                        tipFormatter={(val) => `${(val * 22.01 + 400).toFixed(0)}`}
                        marks={installmentMarks}
                        defaultValue={[
                          priceMin === NET_INSTALLMENT_MIN_PRICE
                            ? 0
                            : (priceMin - 400) / 22.01,
                          priceMax === NET_INSTALLMENT_MAX_PRICE
                            ? 100
                            : (priceMax - 400) / 22.01,
                        ]}
                        onAfterChange={(val) => {
                          setPriceMin(
                            val[0] === 0
                              ? NET_INSTALLMENT_MIN_PRICE
                              : Math.floor(val[0] * 22.01) + 400
                          );
                          setPriceMax(
                            val[1] === 100
                              ? NET_INSTALLMENT_MAX_PRICE
                              : Math.floor(val[1] * 22.01) + 400
                          );
                        }}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        activeDotStyle={activeDotStyle}
                      />
                    </Labeled>
                    <Labeled label="Okres kontraktu" style={labeledStyle}>
                      <Range
                        dots
                        className="mt-2"
                        step={null}
                        marks={durationMarks}
                        tipFormatter={(val) =>
                          `${Math.floor(val / (5/3) + 12).toFixed(0)}`
                        }
                        defaultValue={[
                          (contractDurationMin - 12) * (5/3),
                          (contractDurationMax - 12) * (5/3),
                        ]}
                        onAfterChange={(val) => {
                          setContractDurationMin(Math.floor(val[0] / (5/3) + 12));
                          setContractDurationMax(Math.floor(val[1] / (5/3) + 12));
                        }}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        activeDotStyle={activeDotStyle}
                      />
                    </Labeled>
                    <Labeled label="Przebieg roczny" style={labeledStyle}>
                      <Range
                        dots
                        className="mt-2"
                        step={null}
                        marks={mileageMarks}
                        tipFormatter={(val) =>
                          `${(Math.ceil(val) * 300 + 10000).toFixed(0)}`
                        }
                        defaultValue={[
                          (annualMileageMin - 10000) / 300,
                          (annualMileageMax - 10000) / 300,
                        ]}
                        onAfterChange={(val: number[]) => {
                          setAnnualMileageMin(Math.ceil(val[0] * 3) * 100 + 10000);
                          setAnnualMileageMax(Math.ceil(val[1] * 3) * 100 + 10000);
                        }}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        activeDotStyle={activeDotStyle}
                      />
                    </Labeled>
                    <Labeled label="Wkład własny" style={labeledStyle}>
                      <Range
                        dots
                        className="mt-2"
                        step={null}
                        tipFormatter={(val) =>
                          `${(Math.floor(val) / 3.33).toFixed(0)}`
                        }
                        marks={budgetMarks}
                        defaultValue={[
                          startBudgetMin * 3.33,
                          startBudgetMax * 3.33,
                        ]}
                        onAfterChange={(val: number[]) => {
                          setStartBudgetMin(Math.floor(val[0] / 3.33));
                          setStartBudgetMax(Math.floor(val[1] / 3.33));
                        }}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        activeDotStyle={activeDotStyle}
                      />
                    </Labeled>
                    <Labeled label="Typ nadwozia" style={labeledStyle}>
                      <CarBodyPicker
                        carBodyType={carBodyType}
                        selectedBodyTypes={selectedBodyTypes}
                        setSelectedBodyTypes={setSelectedBodyTypes}
                        carBodyPickerState={carBodyPickerState}
                        setCarBodyPickerState={setCarBodyPickerState}
                      />
                    </Labeled>
                    <Form.Check className="ml-2" name="type" label="Połącz wszystkie warianty oferty dla danego samochodu">
                      <CustomCheckBoxInput
                        checked={aggregateOffer}
                        onClick={() => setAggregateOffer(!aggregateOffer)}
                      />
                      <CustomCheckBoxLabel
                        className="ml-2"
                        onClick={() => setAggregateOffer(!aggregateOffer)}
                      >
                        Połącz wszystkie warianty oferty dla danego samochodu
                      </CustomCheckBoxLabel>
                    </Form.Check>
                  </div>
                </div>
              </Collapse>
              </>) : (
              <div
                className="d-flex justify-content-center justify-content-md-between px-5 pt-5 pb-3 flex-wrap"
                style={{ rowGap: "30px" }}
              >
                <Labeled label="Marka" style={labeledStyle}>
                  <DropdownSelect
                    style={dropdownStyle}
                    options={brands}
                    selected={selectedBrands}
                    setSelected={setSelectedBrands}
                    placeholder={"Marka"}
                  />
                </Labeled>
                <Labeled label="Rata netto" style={labeledStyle}>
                  <Range
                    dots
                    className="mt-2"
                    step={null}
                    tipFormatter={(val) => `${(val * 22.01 + 400).toFixed(0)}`}
                    marks={installmentMarks}
                    defaultValue={[
                      priceMin === NET_INSTALLMENT_MIN_PRICE
                        ? 0
                        : (priceMin - 400) / 22.01,
                      priceMax === NET_INSTALLMENT_MAX_PRICE
                        ? 100
                        : (priceMax - 400) / 22.01,
                    ]}
                    onAfterChange={(val) => {
                      setPriceMin(
                        val[0] === 0
                          ? NET_INSTALLMENT_MIN_PRICE
                          : Math.floor(val[0] * 22.01) + 400
                      );
                      setPriceMax(
                        val[1] === 100
                          ? NET_INSTALLMENT_MAX_PRICE
                          : Math.floor(val[1] * 22.01) + 400
                      );
                    }}
                    trackStyle={trackStyle}
                    handleStyle={handleStyle}
                    activeDotStyle={activeDotStyle}
                  />
                </Labeled>
                <Labeled label="Okres kontraktu" style={labeledStyle}>
                  <Range
                    dots
                    className="mt-2"
                    step={null}
                    marks={durationMarks}
                    tipFormatter={(val) =>
                      `${Math.floor(val / (5/3) + 12).toFixed(0)}`
                    }
                    defaultValue={[
                      (contractDurationMin - 12) * (5/3),
                      (contractDurationMax - 12) * (5/3),
                    ]}
                    onAfterChange={(val) => {
                      setContractDurationMin(Math.floor(val[0] / (5/3) + 12));
                      setContractDurationMax(Math.floor(val[1] / (5/3) + 12));
                    }}
                    trackStyle={trackStyle}
                    handleStyle={handleStyle}
                    activeDotStyle={activeDotStyle}
                  />
                </Labeled>
                <Labeled label="Typ nadwozia" style={labeledStyle}>
                  <CarBodyPicker
                    carBodyType={carBodyType}
                    selectedBodyTypes={selectedBodyTypes}
                    setSelectedBodyTypes={setSelectedBodyTypes}
                    carBodyPickerState={carBodyPickerState}
                    setCarBodyPickerState={setCarBodyPickerState}
                  />
                </Labeled>
                <Labeled label="Przebieg roczny" style={labeledStyle}>
                  <Range
                    dots
                    className="mt-2"
                    step={null}
                    marks={mileageMarks}
                    tipFormatter={(val) =>
                      `${(Math.ceil(val) * 300 + 10000).toFixed(0)}`
                    }
                    defaultValue={[
                      (annualMileageMin - 10000) / 300,
                      (annualMileageMax - 10000) / 300,
                    ]}
                    onAfterChange={(val: number[]) => {
                      setAnnualMileageMin(Math.ceil(val[0] * 3) * 100 + 10000);
                      setAnnualMileageMax(Math.ceil(val[1] * 3) * 100 + 10000);
                    }}
                    trackStyle={trackStyle}
                    handleStyle={handleStyle}
                    activeDotStyle={activeDotStyle}
                  />
                </Labeled>
                <Labeled label="Wkład własny" style={labeledStyle}>
                  <Range
                    dots
                    className="mt-2"
                    step={null}
                    tipFormatter={(val) =>
                      `${(Math.floor(val) / 3.33).toFixed(0)}`
                    }
                    marks={budgetMarks}
                    defaultValue={[
                      startBudgetMin * 3.33,
                      startBudgetMax * 3.33,
                    ]}
                    onAfterChange={(val: number[]) => {
                      setStartBudgetMin(Math.floor(val[0] / 3.33));
                      setStartBudgetMax(Math.floor(val[1] / 3.33));
                    }}
                    trackStyle={trackStyle}
                    handleStyle={handleStyle}
                    activeDotStyle={activeDotStyle}
                  />
                </Labeled>
                <Form.Check className="ml-2" name="type" label="Połącz wszystkie warianty oferty dla danego samochodu">
                  <CustomCheckBoxInput
                    checked={aggregateOffer}
                    onClick={() => setAggregateOffer(!aggregateOffer)}
                  />
                  <CustomCheckBoxLabel
                    className="ml-2"
                    onClick={() => setAggregateOffer(!aggregateOffer)}
                  >
                    Połącz wszystkie warianty oferty dla danego samochodu
                  </CustomCheckBoxLabel>
                </Form.Check>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#fff" }} className="mt-5">
        <div className="container d-flex justify-content-start align-items-center">
          <div
            className="d-flex align-items-center px-3"
            style={{
              height: "50px",
              fontSize: "16px",
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <img
              className="mr-2"
              style={{ height: "20px" }}
              src={carBodiesUnselected.coupe.image}
            />
            <strong> {allOfferCount} ofert / {allCarCount} samochodów</strong>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div
          className="d-flex justify-content-center align-items-baseline pt-3"
          style={{ backgroundColor: "#f5f5f5" }}
        >
          <p className="mr-2">Sortuj:</p>
          <DropdownOptions
            open={false}
            options={sortingOptions}
            selectedOption={orderingField}
            setSelectedOption={setOrderingField}
          ></DropdownOptions>
        </div>
      ) : (
        <div className="" style={{ backgroundColor: "#f5f5f5" }}>
          <div className="container d-flex">
            <StyledTab
              name="net_installment"
              activeName={orderingField.value}
              onClick={() =>
                setOrderingField(
                  sortingOptions.find(
                    (item) => item.value === "net_installment"
                  )!
                )
              }
            >
              <StyledTabText>Rata rosnąco</StyledTabText>
            </StyledTab>
            <StyledTab
              name="-net_installment"
              activeName={orderingField.value}
              onClick={() =>
                setOrderingField(
                  sortingOptions.find(
                    (item) => item.value === "-net_installment"
                  )!
                )
              }
            >
              <StyledTabText>Rata malejąco</StyledTabText>
            </StyledTab>
            <StyledTab
              name="-popularity"
              activeName={orderingField.value}
              onClick={() =>
                setOrderingField(
                  sortingOptions.find((item) => item.value === "-popularity")!
                )
              }
            >
              <StyledTabText>Najpopularniejsze</StyledTabText>
            </StyledTab>
          </div>
        </div>
      )}
      <div style={{ backgroundColor: "#f5f5f5" }}>
        {loading ? (
          <div
            className="d-flex flex-wrap justify-content-md-center align-items-center"
            style={{
              minHeight: "200px",
              marginLeft: "auto",
              width: "100vw",
              marginRight: "auto",
              maxWidth: "1500px",
            }}
          >
            <Spinner animation="border" variant="primary"></Spinner>
          </div>
        ) : aggregateOffer ? ( aggregatedOffersCards.length > 0 ?
          (
          <>
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{
                minHeight: "200px",
                marginLeft: "auto",
                width: "100%",
                marginRight: "auto",
                maxWidth: "1500px",
              }}
            >
              {aggregatedOffersCards}
            </div>
            <div className="d-flex justify-content-center mt-4 mb-4 mb-md-0">
              <Button
                disabled={aggregateOffer ? !nextAggregatedPageAvailable : !nextAvailable}
                variant="secondary"
                style={{ width: "250px" }}
                onClick={() => setPage(page + 1)}
                onMouseDown={(e) => e.preventDefault()}
              >
                Załaduj kolejne
              </Button>
            </div>
          </>
          )
          :
          (
          <div className="d-flex justify-content-center align-items-center flex-column py-5">
            <img
              className="my-2"
              style={{ height: "50px", opacity: "0.3" }}
              src={carBodiesUnselected.coupe.image}
            />
            <h5>Brak ofert spełniających dane kryteria</h5>
          </div>
          )
        ) : ( offerCards.length > 0 ? (
          <>
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{
                minHeight: "200px",
                marginLeft: "auto",
                width: "100%",
                marginRight: "auto",
                maxWidth: "1500px",
              }}
            >
              {offerCards}
            </div>
            <div className="d-flex justify-content-center mt-4 mb-4 mb-md-0">
              <Button
                disabled={aggregateOffer ? !nextAggregatedPageAvailable : !nextAvailable}
                variant="secondary"
                style={{ width: "250px" }}
                onClick={() => setPage(page + 1)}
                onMouseDown={(e) => e.preventDefault()}
              >
                Załaduj kolejne
              </Button>
            </div>
          </>
          ) : (
          <div className="d-flex justify-content-center align-items-center flex-column py-5">
            <img
              className="my-2"
              style={{ height: "50px", opacity: "0.3" }}
              src={carBodiesUnselected.coupe.image}
            />
            <h5>Brak ofert spełniających dane kryteria</h5>
          </div>
          )
        )}
      </div>
    </>
  );
};
export default SearchLeasingsView;

const leasingSearchViewRoot = document.getElementById(
  "react--leasing-advanced-search"
);
if (leasingSearchViewRoot) {
  ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={captchaKey}
    language="pl"
  >
    <SearchLeasingsView />
  </GoogleReCaptchaProvider>,
 leasingSearchViewRoot);
}
