import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { SelectItem } from "../../static-data/FilterOptions";

interface Props {
  open: boolean;
  options: SelectItem[];
  selectedOption: SelectItem;
  setSelectedOption: (item: SelectItem) => void;
}

export const DropdownWrapper = styled.div<DropdownArrowProps>`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #404040;
  border-radius: 10px;
  opacity: 1;
  width: 250px;
  height: 100%;
  min-height: 36px;
  margin: auto;
  transition: border-radius ${(props) => (props.open ? ".0" : ".2")}s
    ${(props) => (props.open ? ".0" : ".5")}s;
`;

export const Dropdown = styled.div`
  height: inherit;
`;

interface DropdownArrowProps {
  open: boolean;
}
export const DropdownArrow = styled.div<DropdownArrowProps>`
  border: solid #354052;
  border-width: 0 2px 2px 0;
  width: 3px;
  height: 3px;
  padding: 3px;
  margin-right: 10px;
  transition: transform 0.5s;
  cursor: pointer;
  transform: rotate(${(props) => (props.open ? "45deg" : "-135deg")});
`;

export const SearchInput = styled.input`
  border-style: none;
  outline: none;
  text-align: left;
  font: normal normal normal 14px/12px Montserrat, serif;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  height: 18px;
  margin-left: 10px;
`;
const Collapse = styled.div<DropdownArrowProps>`
  width: 250px;
  max-height: 200px;
  position: absolute;
  top: 33px;
  left: -1px;
  z-index: 3;
  border: ${(props) => (props.open ? 1 : 0)}px solid #404040;
  overflow: auto;
  background-color: #fff;
  height: ${(props) => (props.open ? "auto" : "0px")};
  transition: height 0.5s, border 0.5s 0s;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f3f3f3;
  }
  &::-webkit-scrollbar-thumb {
    background: #f6a335;
    border: 1px solid #f6a335;
  }
`;
const Option = styled.div<OptionProps>`
  height: 31px;
  padding: 5px 10px;
  font-size: 14px;
  cursor:pointer;
  background-color: ${(props) => (props.selected ? '#ddd' : '#fff')};

  &:hover {
    background-color: #f6a335;
    color: white;
  }
`;

interface OptionProps {
  selected: boolean;
}


const DropdownOptions = (props: Props) => {
  const { options = [], selectedOption, setSelectedOption } = props;

  const [open, setOpen] = useState(false);

  return (
    <div>
      <DropdownWrapper
        className={`${
          open ? "without-bottom-radius in" : ""
        } d-flex align-items-center justify-content-between`}
        aria-expanded={open}
        open={open}
        aria-controls="collapse"
        onClick={() => {setOpen(!open)}}
        style={{ position: "relative" }}
      >
        <SearchInput
          type="text"
          value={selectedOption.name}
          autoComplete="off"
          readOnly
        />
        <DropdownArrow
          onClick={() => setOpen(!open)}
          open={open}
        ></DropdownArrow>

        <Collapse open={open}>
          {options.map((item) => {
            return (
              <Option selected={item.id === selectedOption.id} onClick={() => {setOpen(!open); setSelectedOption(item)}} key={item.id}>
                <p className="mb-0">{item.name}</p>
              </Option>
            );
          })}
        </Collapse>
      </DropdownWrapper>
    </div>
  );
};

export default DropdownOptions;
