import { useState, useEffect } from 'react';
export enum ScreenBreakpoints {
    // Same as in bootstrap.
    SMALL = 768,
    MEDIUM = 992,
    LARGE = 1200,
    EXTRA_LARGE = 1400,
  }
  

const useMobileRecognizer = (screenBreakpoint: ScreenBreakpoints) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= screenBreakpoint;
};

export default useMobileRecognizer;
