import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  options: { id: any; name: string }[];
  setOptions?: (items: any[]) => void;
  selected: any[];
  setSelected: (items: any[]) => void;
  placeholder?: string;
  style?: any;
  withCheckboxes?: boolean;
  withSelectAll?: boolean;
}

export const DropdownWrapper = styled.div<DropdownArrowProps>`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #404040;
  border-radius: 10px;
  opacity: 1;
  width: 250px;
  height: 100%;
  min-height: 36px;
  margin: auto;
  transition: border-radius ${(props) => (props.open ? ".0" : ".2")}s
    ${(props) => (props.open ? ".0" : ".5")}s;
`;

export const Dropdown = styled.div`
  height: inherit;
`;

interface DropdownArrowProps {
  open: boolean;
  numOfElements: number;
}
export const DropdownArrow = styled.div<DropdownArrowProps>`
  border: solid #354052;
  border-width: 0 2px 2px 0;
  width: 3px;
  height: 3px;
  padding: 3px;
  margin-right: 10px;
  transition: transform 0.5s;
  cursor: pointer;
  transform: rotate(${(props) => (props.open ? "45deg" : "-135deg")});
`;

export const SearchInput = styled.input`
  border-style: none;
  outline: none;
  text-align: left;
  font: normal normal normal 14px/12px Montserrat, serif;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
  height: 18px;
  margin-left: 10px;
`;
const Collapse = styled.div<DropdownArrowProps>`
  width: 250px;
  max-height: 155px;
  @media only screen and (max-width: 767px) {
    max-height: 400px
  }
  position: absolute;
  top: 33px;
  left: -1px;
  z-index: 3;
  border: ${(props) => (props.open ? 1 : 0)}px solid #404040;
  overflow-y: scroll;
  background-color: #fff;
  height: ${(props) =>
    props.open ? `${(props.numOfElements + 1) * 26}px` : "0px"};
  transition: height 0.5s, border 0.5s 0s;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f3f3f3;
  }
  &::-webkit-scrollbar-thumb {
    background: #f6a335;
    border: 1px solid #f6a335;
  }
`;
const Option = styled.div`
  height: 24px;
`;
const DropdownSelect = (props: Props) => {
  const {
    options = [],
    selected,
    setSelected,
    placeholder,
    style = {},
    withCheckboxes = true,
    withSelectAll = true,
  } = props;
  const [internalOptions, setInternalOptions] = useState<any[]>([]);
  useEffect(() => {
    setInternalOptions([...options]);
  }, [options]);
  const [serachInput, setSearchInput] = useState("");
  useEffect(() => {
    setInternalOptions(
      options.filter((item) =>
        item.name.toLowerCase().startsWith(serachInput.toLowerCase())
      )
    );
  }, [serachInput]);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const selectItem = (item: any) => {
    if (withCheckboxes) {
      // Handle it as multi choice
      if (selected.includes(item)) {
        setSelected(selected.filter((sel) => sel.id !== item.id));
      } else {
        setSelected([...selected, item]);
      }
    } else {
      setSelected([item]);
    }
  };
  let joinedInputValue = selected.map((item) => item.name).join(", ");
  if (joinedInputValue.length > 16) {
    joinedInputValue = joinedInputValue.substring(0, 16) + "...";
  }
  const dropdown = useRef(null);
  useEffect(() => {
    if (dropdown) {
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(type, (evt) => {
          //@ts-ignore
          if (dropdown?.current.contains(evt.target)) return;
          setOpen(false);
        });
      });
    }
  });
  return (
    <div ref={dropdown}>
      <DropdownWrapper
        className={`${
          open ? "without-bottom-radius in" : ""
        } d-flex align-items-center justify-content-between`}
        aria-expanded={open}
        open={open}
        aria-controls="collapse"
        numOfElements={options.length}
        onClick={() => {
          setOpen(true);
        }}
        style={{ position: "relative", ...style }}
      >
        <SearchInput
          type="text"
          placeholder={placeholder}
          //@ts-ignore
          onInput={(e: InputEvent) => setSearchInput(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
          }}
          disabled={!withCheckboxes}
          onClick={() => {
            setOpen(true);
          }}
          value={focused ? serachInput : joinedInputValue}
          autoComplete="off"
        />
        <DropdownArrow
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
          numOfElements={options.length}
          open={open}
        ></DropdownArrow>

        <Collapse open={open} numOfElements={options.length}>
          {withSelectAll && (
            <Option
              className="ml-2"
              onClick={
                withCheckboxes
                  ? () => {
                      if (selected.length === 0) {
                        setSelected(options);
                      } else {
                        setSelected([]);
                      }
                    }
                  : () => {}
              }
            >
              <Form.Check
                label="Wszystkie"
                type="checkbox"
                bsPrefix="custom-dropdown-checkbox"
                checked={selected.length === options.length}
                onChange={() => {
                  if (selected.length === 0) {
                    setSelected(options);
                  } else {
                    setSelected([]);
                  }
                }}
              />
            </Option>
          )}
          {internalOptions.map((item: any, index: number) => {
            let name = (item.displayName
              ? item.displayName
              : item.name
            ).substring(0, 16);
            if (name.length >= 16) {
              name += '...';
            }
            return (
              <Option
                className="ml-2"
                key={index}
                onClick={
                  withCheckboxes
                    ? () => {
                        selectItem(item);
                        setSearchInput("");
                      }
                    : () => {}
                }
              >
                {withCheckboxes ? (
                  <Form.Check
                    label={name}
                    type="checkbox"
                    bsPrefix="custom-dropdown-checkbox"
                    checked={selected.includes(item)}
                    onChange={() => {
                      selectItem(item);
                      setSearchInput("");
                    }}
                  />
                ) : (
                  <p
                    onClick={() => {
                      selectItem(item);
                      setOpen(false);
                      setSearchInput("");
                    }}
                  >
                    {name}
                  </p>
                )}
              </Option>
            );
          })}
        </Collapse>
      </DropdownWrapper>
    </div>
  );
};

export default DropdownSelect;
