import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Col, Row } from "react-bootstrap";
import ReactDOM from "react-dom";
import {
  changePeriodFormat,
  changeSelfDepositFormat,
  changeMileageLimitFormat,
  changeCarValueFormat,
} from "src/scripts/misc/dataFromatters";
import {
  fetchLeasingOfferDetails,
  fetchSimilarLeasingOffers,
} from "src/scripts/services/leasing.resource";
import { LeasingOffer } from "../../models/LeasingOffer";
import LeasingOfferBrowser from "./LeasingOfferBrowser/LeasingOfferBrowser";
import LeasingOfferGallery from "./LeasingOfferGallery/LeasingOfferGallery";
import { OfferCarPrice } from "./Styled";
import { imagePlaceholder } from "src/scripts/enums/defaults";

const LeasingOfferView = () => {
  const [leasingOffer, setLeasingOffer] = useState<LeasingOffer>();
  const [leasingOffers, setLeasingOffers] = useState<LeasingOffer[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchSimilar = async (id: number) => {
      const response = await fetchSimilarLeasingOffers(id);
      if (response.data.results) {
        setLeasingOffers(response.data.results);
      }
    };

    const fetchData = async () => {
      const url = new URL(window.location.href);
      const pk = parseInt(url.pathname.split("/")[4]);
      const response = await fetchLeasingOfferDetails(pk);
      if (response.data) {
        setLeasingOffer(response.data);
        if (response.data.car) {
          fetchSimilar(response.data.car.id);
        }
      }
      setLoading(false);
    };
    fetchData();
    
  }, []);
  let brandName =
    leasingOffer?.car.brand.name && leasingOffer?.car.brand.name.length > 15
      ? leasingOffer?.car.brand.name.slice(0, 15) + "..."
      : leasingOffer?.car.brand.name;
  let modelName =
    leasingOffer?.car.brand.name && leasingOffer?.car.model.length > 15
      ? leasingOffer?.car.model.slice(0, 15) + "..."
      : leasingOffer?.car.model;
  let specification = leasingOffer?.car.specification;
  var offer_url = new URL(window.location.href.split('?')[0]);
  const noImgDealer = window.location.origin + imagePlaceholder;
  let car_avatar_url = new URL(window.location.protocol + '//' + window.location.host) + (leasingOffer?.car?.avatar ? leasingOffer.car.avatar : noImgDealer);
  let hostname = location.hostname;

  let newDate = new Date();
  let date = newDate.getDate();
  newDate.setMonth(newDate.getMonth()+8);
  let month = newDate.getMonth();
  let year = newDate.getFullYear();
  let separator = '-'
  let priceValidUntil = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`

  return (
    <div>
      <Helmet>
        <title>{`Wynajem długoterminowy dla ${brandName} ${modelName} ${specification}`}</title>
        <meta name="description" content=
          {`Wynajem długoterminowy ${brandName} ${modelName} ${specification},
             miesięczna rata netto: ${leasingOffer?.net_installment} zł,
             miesięczna rata brutto: ${leasingOffer?.gross_installment} zł,
          `}/>
        <meta property="og:description" content=
          {`Wynajem długoterminowy ${brandName} ${modelName} ${specification},
             wartość samochodu: ${leasingOffer?.value} zł
             miesięczna rata netto: ${leasingOffer?.net_installment} zł,
             miesięczna rata brutto: ${leasingOffer?.gross_installment} zł,
             okres wynajmu: ${leasingOffer?.period} miesięcy,
             wpłata wstępna: ${leasingOffer?.self_deposit}%,
             limit roczny: ${leasingOffer?.mileage_limit} km.
          `}/>
        <meta name="robots" content="max-image-preview:large"/>
        <link rel="canonical" href={`${offer_url}`}/>

        <meta name="twitter:card" content="summary"/>
        <meta property="twitter:title" content={`Wynajem długoterminowy dla ${brandName} ${modelName} ${specification}`}/>
        <meta property="twitter:description" content=
          {`Wynajem długoterminowy ${brandName} ${modelName} ${specification},
             wartość samochodu: ${leasingOffer?.value} zł
             miesięczna rata netto: ${leasingOffer?.net_installment} zł,
             miesięczna rata brutto: ${leasingOffer?.gross_installment} zł,
             okres wynajmu: ${leasingOffer?.period} miesięcy,
             wpłata wstępna: ${leasingOffer?.self_deposit}%,
             limit roczny: ${leasingOffer?.mileage_limit} km.
          `}/>
        <meta property="twitter:image" content={`${car_avatar_url}`}/>
        <meta property="twitter:image:alt" content={` Głównie zdjęcie ${brandName} ${modelName}`}/>

        <meta property="og:title" content={`Prezentacja oferty wynajmu długoterminowego dla ${brandName} ${modelName} ${specification}`}/>
        <meta property="og:image" content={`${car_avatar_url}`}/>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": ["Product", "Car"],
            "sku": "${leasingOffer?.id}",
            "brand": "${brandName}",
            "name": "Wynajem długoterminowy ${brandName} ${modelName} za ${leasingOffer?.net_installment} zł",
            "alternateName": "Auto na abonament ${brandName} ${modelName} za ${leasingOffer?.net_installment} zł",
            "description": "Wynajem długoterminowy ${brandName} ${modelName} ${specification},
             wartość samochodu: ${leasingOffer?.value} zł,
             miesięczna rata netto: ${leasingOffer?.net_installment} zł,
             miesięczna rata brutto: ${leasingOffer?.gross_installment} zł,
             okres wynajmu: ${leasingOffer?.period} miesięcy,
             wpłata wstępna: ${leasingOffer?.self_deposit}%,
             limit roczny: ${leasingOffer?.mileage_limit} km.",
            "offers": {
              "@type": "Offer",
              "url": "${offer_url}",
              "areaServed": "PL",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock",
              "businessFunction": "LeaseOut",
              "leaseLength": {
                "@type": "QuantitativeValue",
                "value": ${leasingOffer?.period},
                "unitText": "miesięcy"
              },
              "price" : "${leasingOffer?.net_installment}",
              "priceValidUntil": "${priceValidUntil}",
              "priceCurrency" : "PLN",
              "name": "Wynajem długoterminowy ${brandName} ${modelName} za ${leasingOffer?.net_installment} zł",
              "alternateName": "Auto na abonament ${brandName} ${modelName} za ${leasingOffer?.net_installment} zł",
              "description": "Wynajem długoterminowy ${brandName} ${modelName} ${specification},
               wartość samochodu: ${leasingOffer?.value} zł,
               miesięczna rata netto: ${leasingOffer?.net_installment} zł,
               miesięczna rata brutto: ${leasingOffer?.gross_installment} zł,
               okres wynajmu: ${leasingOffer?.period} miesięcy,
               wpłata wstępna: ${leasingOffer?.self_deposit}%,
               limit roczny: ${leasingOffer?.mileage_limit} km.",
               "image": "${car_avatar_url}"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5.0",
              "reviewCount": "1"
            },
            "review": [
              {
                "@type": "Review",
                "author": {
                  "@type": "Person",
                  "name": "Piotr"
                },
                "datePublished": "2022-08-10",
                "reviewBody": "Oferta wygląda na interesującą. Myślę, że warto poprosić o kontakt i uzyskać więcej szczegółów.",
                "name": "Oferta od importera",
                "reviewRating": {
                  "@type": "Rating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "worstRating": "1"
                }
              }
            ]
          }`}
        </script>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Oferty wynajmu długoterminowego",
                "alternateName": "Auta na abonament wszystkich marek",
                "item": "https://${hostname}/finansowanie/wynajem/",
                "url": "https://${hostname}/finansowanie/wynajem/"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "${brandName}",
                "alternateName": "Auta na abonament marki ${brandName}",
                "item": "https://${hostname}/finansowanie/wynajem/search?car_brand_id=${leasingOffer?.car.brand.id}",
                "url": "https://${hostname}/finansowanie/wynajem/search?car_brand_id=${leasingOffer?.car.brand.id}"
              }]
            }
          `}
        </script>
      </Helmet>
      <Row className="offer-details--container--title">
        <Col>
          <a
            className="offer-details--container--title--icon"
            href="/finansowanie/wynajem/"
          >
            <img
              src="/static/dist/public/assets/icons/icon-home.svg"
              alt="home"
            />
          </a>
          /{" "}
          <a href={`/finansowanie/wynajem/search${document.location.search}`}>
            Wyniki wyszukiwania
          </a>
          /{" "}
          <a
            href={`/finansowanie/wynajem/search?car_brand_id=${leasingOffer?.car.brand.id}`}
          >
            {leasingOffer?.car.brand.name}
          </a>
          / <b>{leasingOffer?.id}</b>
        </Col>
      </Row>
      <Row className="mt-lg-1 mt-1">
        <Col md>
          <h1>
            <div className="offer-details--container--header">
              Oferta wynajmu długoterminowego
            </div>
            <div className="d-flex offer-details--container--info--details align-items-end">
              <div className="w-50">
                <p className="offer-details--container--info--details--brand">
                  {brandName}
                </p>
                <p className="offer-details--container--info--details--model mb-0">
                  {modelName}
                </p>
              </div>
              <div className="offer-details--container--info--details--price">
                <p className="offer-details--container--info--details--price--net">
                  {" "}
                  {leasingOffer?.net_installment} zł/mc netto
                </p>
                <p className="offer-details--container--info--details--price--gross mb-0">
                  {" "}
                  {leasingOffer?.gross_installment} zł/mc brutto
                </p>
              </div>
            </div>
            <div>
              <div className="w-100 offer-details--container--info--details--specification">
                <p>
                  {specification}
                </p>
              </div>
            </div>
          </h1>
          <div className="d-flex offer-details--container--info--gray-bar mt-2">
            <div className="mx-auto mr-lg-auto">
              <p className="offer-details--container--info--gray-bar--title">
                Okres:
              </p>
              <p className="offer-details--container--info--gray-bar--value">
                {changePeriodFormat(leasingOffer?.period)}
              </p>
            </div>
            <div className="mx-auto">
              <p className="offer-details--container--info--gray-bar--title">
                Wpłata ({leasingOffer?.self_deposit}%):
              </p>
              <p className="offer-details--container--info--gray-bar--value">
                {changeSelfDepositFormat(
                  leasingOffer?.self_deposit,
                  leasingOffer?.value
                )}
              </p>
            </div>
            <div className="mx-auto">
              <p className="offer-details--container--info--gray-bar--title">
                Limit roczny:
              </p>
              <p className="offer-details--container--info--gray-bar--value">
                {changeMileageLimitFormat(leasingOffer?.mileage_limit)}
              </p>
            </div>
          </div>
          <Row>
            <Col>
              <OfferCarPrice className="mt-2 mb-2 mx-auto font-weight-bold">Przybliżona wartość
              samochodu {changeCarValueFormat(leasingOffer?.value)} brutto</OfferCarPrice>
            </Col>
          </Row>
        </Col>
        <Col className="col d-flex align-items-end flex-column mt-3">
          <div className="d-flex offer-details--container--icons justify-content-end w-100">
            <div className="d-flex mx-auto ml-lg-4 mx-lg-0 flex-column">
              <img
                className="m-auto"
                src={`/static/dist/public/assets/leasing/tire_${
                  leasingOffer?.tires_included ? "active" : "disabled"
                }.svg`}
                alt="tire"
              />
              <p
                className={`offer-details--container--icons--description ${
                  leasingOffer?.tires_included ? "font-weight-bold" : ""
                }`}
              >
                Opony
              </p>
            </div>
            <div className="d-flex mx-auto ml-lg-4 mx-lg-0 flex-column">
              <img
                className="m-auto"
                src={`/static/dist/public/assets/leasing/inspection_${
                  leasingOffer?.insurance_included ? "active" : "disabled"
                }.svg`}
                alt="inspection"
              />
              <p
                className={`offer-details--container--icons--description ${
                  leasingOffer?.insurance_included ? "font-weight-bold" : ""
                }`}
              >
                Ubezpieczenie
              </p>
            </div>
            <div className="d-flex mx-auto ml-lg-4 mx-lg-0 flex-column">
              <img
                className="m-auto"
                src={`/static/dist/public/assets/leasing/trailer_${
                  leasingOffer?.assistance_included ? "active" : "disabled"
                }.svg`}
                alt="trailer"
              />
              <p
                className={`offer-details--container--icons--description ${
                  leasingOffer?.assistance_included ? "font-weight-bold" : ""
                }`}
              >
                Assistance
              </p>
            </div>
            <div className="d-flex mx-auto ml-lg-4 mx-lg-0 flex-column">
              <img
                className="m-auto"
                src={`/static/dist/public/assets/leasing/mechanic_${
                  leasingOffer?.service_included ? "active" : "disabled"
                }.svg`}
                alt="mechanic"
              />
              <p
                className={`offer-details--container--icons--description ${
                  leasingOffer?.service_included ? "font-weight-bold" : ""
                }`}
              >
                Serwis
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-end mt-2 offer-details--container--contact-button w-100">
            <a
              href={`/finansowanie/wynajem/offer/${leasingOffer?.id}/contact${document.location.search}`}
              className="btn btn-primary"
            >
              Poproś o kontakt
            </a>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <LeasingOfferGallery
            images={leasingOffer?.car.images}
            loading={loading}
          ></LeasingOfferGallery>
        </Col>
      </Row>
      <Row className="offer-details--container--description">
        <Col>
          <h2 className="offer-details--container--description--title">Opis</h2>
          <div className="offer-details--container--description--content"
               dangerouslySetInnerHTML={{ __html: leasingOffer?.long_description ?? "" }}>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-center mt-2 offer-details--container--contact-button w-100">
            <a
              href={`/finansowanie/wynajem/offer/${leasingOffer?.id}/contact${document.location.search}`}
              className="btn btn-primary"
            >
              Poproś o kontakt
            </a>
          </div>
        </Col>
      </Row>
      <Row className="offer-details--container--views mt-2">
        <Col>
          <p className="text-center">
            {leasingOffer?.popularity} wyświetleń oferty
          </p>
          {/*<p className="text-center">{leasingOffer?.inquiries_sent} kontaktów ws. oferty</p> */}
        </Col>
      </Row>
      <Row className="offer-details--container--disclaimer mt-2">
        <Col>
          <ul>
            <li>Publikowane zdjęcia są jedynie ilustracją.</li>
            <li>Niniejsze ogłoszenie zostało pobrane bezpośrednio od importera
            danej marki i jest wyłącznie informacją handlową, nie stanowi
            oferty w myśl art. 66 §1. Kodeksu Cywilnego.</li>
            <li>Wszelkie informacje przedstawione na tej stronie zostały zamieszczone
            wyłącznie w celach poglądowych i są orientacyjne. Wiążące ustalenie ceny,
            wyposażenia i specyfikacji pojazdu następuje w umowie jego sprzedaży,
            a określenie parametrów technicznych zawiera świadectwo homologacji typu pojazdu.</li>
            <li>Zawarcie umowy oraz jej warunki uzależnione są od wyniku oceny sytuacji finansowej
            Klienta oraz jego zdolności do zaciągania i regulowania zobowiązań.</li>
            <li>Wysłanie zapytania na podstawie informacji zawartych na stronie za pomocą formularza
            elektronicznego oznacza zgłoszenie zainteresowania wybranym modelem samochodu w danej
            specyfikacji i wyrażenie zgody na kontakt ze strony autoryzowanego przedstawiciela marki
            w celu przedstawienia szczegółowej oferty sprzedaży i finansowania.</li>
            <li>Użyte logo i nazwa firmy są własnością ich prawnych właścicieli i zostały
            zaprezentowane tylko do celów informacyjnych.</li>
          </ul>
        </Col>
      </Row>
      {leasingOffers && leasingOffers?.length > 1 && (
        <Row className="mt-lg-1 mt-1">
          <Col>
            <LeasingOfferBrowser
              selectedLeasingOffer={leasingOffer}
              leasingOffers={leasingOffers}
              setSelectedLeasingOffer={setLeasingOffer}
            ></LeasingOfferBrowser>
          </Col>
        </Row>
      )}
    </div>
  );
};
const leasingOfferView = document.getElementById("react--leasing-offer-view");
if (leasingOfferView) {
  ReactDOM.render(<LeasingOfferView />, leasingOfferView);
}
