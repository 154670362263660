import React, { useEffect, useState } from "react";
import DropdownSelect from "src/scripts/components/common/DropdownSelect";
import { fetchLeasingCarBrands } from "src/scripts/services/leasing.resource";
import ReactDOM from "react-dom";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Slider, {
  SliderTooltip,
  Handle,
  createSliderWithTooltip,
} from "rc-slider";
import {
  activeDotStyle,
  handleStyle,
  installmentMarks,
  NET_INSTALLMENT_MAX_PRICE,
  NET_INSTALLMENT_MIN_PRICE,
  trackStyle,
} from "src/scripts/static-data/SliderSettings";
export interface Brand {
  id: number;
  name: string;
  displayName: string;
  icon: string;
  counter: number;
}
const Range = createSliderWithTooltip(Slider.Range);
const StyledButton = styled(Button)`
  padding-left: 25%;
  padding-right: 25%;
  height: 50px;
    &:hover {
    color: #fff;
    background-color: #f6a335;;
    border-color: #fff;
  }
`;

const SearchLeasingForm = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [priceMin, setPriceMin] = useState<number>(NET_INSTALLMENT_MIN_PRICE);
  const [priceMax, setPriceMax] = useState<number>(NET_INSTALLMENT_MAX_PRICE);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchLeasingCarBrands();
      if (response.data.results) {
        let brandsWithCounters = response.data.results
          .map((brand) => {
            return {
              ...brand,
              displayName: `${brand.name}(${brand.counter || 0})`,
            };
          })
          .filter((brand) => brand.counter > 0);
        setBrands(brandsWithCounters);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="mt-5 mb-4 container">
      <Row>
        <Col className="d-flex justify-content-center">
          <DropdownSelect
            options={brands}
            selected={selectedBrands}
            setSelected={setSelectedBrands}
            placeholder="Marka"
          />
        </Col>
        <Col className="d-flex justify-content-center mt-3 mt-lg-0">
          <Range
            dots
            className="mt-2"
            step={null}
            tipFormatter={(val) => `${(val * 22.01 + 400).toFixed(0)}`}
            style={{ width: "250px" }}
            marks={installmentMarks}
            defaultValue={[
              priceMin === NET_INSTALLMENT_MIN_PRICE
                ? 0
                : (priceMin - 400) / 22.01,
              priceMax === NET_INSTALLMENT_MAX_PRICE
                ? 100
                : (priceMax - 400) / 22.01,
            ]}
            onChange={(val: number[]) => {
              setPriceMin(
                val[0] === 0
                  ? NET_INSTALLMENT_MIN_PRICE
                  : Math.floor(val[0] * 22.01) + 400
              );
              setPriceMax(
                val[1] === 100
                  ? NET_INSTALLMENT_MAX_PRICE
                  : Math.floor(val[1] * 22.01) + 400
              );
            }}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            activeDotStyle={activeDotStyle}
          />
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center">
        <StyledButton
          variant="primary"
          className="py-2 d-flex align-items-center button-primary"
          href={`/finansowanie/wynajem/search?min_net_installment=${priceMin}&max_net_installment=${priceMax}&car_brand_id=${selectedBrands
            .map((item) => item.id)
            .join(",")}`}
        >
          {" "}
          <span>Szukaj ofert</span>{" "}
        </StyledButton>
      </Row>
    </div>
  );
};
const leasingHomepageFormRoot = document.getElementById(
  "react--leasing-homepage-form"
);
if (leasingHomepageFormRoot) {
  ReactDOM.render(<SearchLeasingForm />, leasingHomepageFormRoot);
}
