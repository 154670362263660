import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Dealer } from "../../models/Dealer";

import {
  Card,
  Image,
  DealerInfo,
  DealerName,
  ConsultantsRow,
  ConsultantsTitle,
  AllWorkers,
  WorkerStar,
  WorkerRating,
  WorkerName,
  ShowMoreWorkers,
  WorkerOpinions,
  DealerAddress,
  Dot,
  DealerType,
  Chip,
  ChipText,
  AllOpinions,
  AllOpinionsArrow,
  WorkerOpinionsArrow,
  ShowMoreWorkersArrow,
  ShowLessWorkersArrow,
  ImageCol,
  ShowMore,
} from "./Styled";

import { Worker } from "../../models/Worker";
import { imagePlaceholder } from "src/scripts/enums/defaults";

interface Props {
  dealer: Dealer;
  selectedDealers?: number[];
  setSelectedDealers: (dealers: number[]) => void;
  selectedWorkers?: number[];
  setSelectedWorkers: (workers: number[]) => void;
}

const DealerCard = (props: Props) => {
  const {
    dealer,
    selectedDealers,
    setSelectedDealers,
    selectedWorkers,
    setSelectedWorkers,
  } = props;
  const [selectedWorkerId, setSelectedWorkerId] = useState<number>(-1);
  const [extendedWorkerView, setExtendedWorkerView] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const selectWorker = (worker: Worker) => {
    if (selectedWorkerId === -1 || selectedWorkerId === worker.id) {
      if (selectedWorkers && selectedWorkers.includes(worker.id)) {
        setSelectedWorkerId(-1);
        setSelectedWorkers(selectedWorkers.filter((sel) => sel !== worker.id));
      } else {
        setSelectedWorkerId(worker.id);
        if (selectedWorkers) {
          setSelectedWorkers([...selectedWorkers, worker.id]);
        } else {
          setSelectedWorkers([worker.id]);
        }
      }
    }
  };

  const selectDealer = (dealer: Dealer) => {
    if (selectedDealers && selectedDealers.includes(dealer.id)) {
      setSelectedDealers(selectedDealers.filter((sel) => sel !== dealer.id));
    } else {
      if (selectedDealers) {
        setSelectedDealers([...selectedDealers, dealer.id]);
      } else {
        setSelectedDealers([dealer.id]);
      }
    }
  };

  const getRate = (currentValue: number, value: number) => {
    currentValue = currentValue + 1;
    if (currentValue <= Math.floor(value)) {
      return 10;
    }
    if (currentValue > Math.ceil(value)) {
      return 0;
    }
    const decimal = (value - Math.floor(value)) * 10;
    return Math.round(decimal);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <Card className="container">
      <Row>
        {dealer.paid && (
          <ImageCol
            $haveworkers={
              dealer.workers.filter((worker) => worker.salon).length !== 0
            }
            className="p-0"
          >
            <Image
              style={{ objectFit: "cover" }}
              haveworkers={
                dealer.workers.filter((worker) => worker.salon).length !== 0
              }
              src={
                dealer.image
                  ? dealer.image
                  : imagePlaceholder
              }
              alt={`Zdjęcie profilowe dealera ${dealer.display_name}`}
            ></Image>
          </ImageCol>
        )}
        <DealerInfo
          $paid={dealer.paid}
          className="p-0"
          $haveworkers={
            dealer.workers.filter((worker) => worker.salon).length !== 0
          }
        >
          <div className="container h-100">
            <Row className="pt-3">
              <Col>
                <Form.Check
                  id={`dealer-${dealer.id}`}
                  type="checkbox"
                  label="Wyślij maila z zapytaniem"
                  bsPrefix="custom-dropdown-checkbox"
                  checked={
                    selectedDealers && selectedDealers.includes(dealer.id)
                  }
                  onChange={() => {
                    selectDealer(dealer);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-2 gy-3">
              <Col className="col-auto d-flex">
                {[...Array(5)].map((_, i) => (
                  <img
                    key={`star-${i}`}
                    src={`/static/dist/public/assets/global/star-${getRate(i, dealer.average_rating)}-10.svg`}
                  ></img>
                ))}
                <p className="mb-0 ml-2">
                  {dealer.average_rating.toFixed(2)}({dealer.rating_count})
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <DealerName className="mb-auto">
                  {dealer.display_name}
                </DealerName>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex">
                <DealerAddress className="mb-auto">
                  {dealer.address_fields.location_string}
                </DealerAddress>
                <Dot className="my-auto rounded-circle mx-2"></Dot>
                <DealerType className="mb-auto">
                  {dealer.salon && "SALON"}{" "}
                  {dealer.service && dealer.salon && " | "}{" "}
                  {dealer.service && "SERWIS"}
                </DealerType>
              </Col>
            </Row>
            <Row className="mt-2 mx-0">
              {dealer.car_brands.map((brand, i) => (
                <Col className="col-auto p-1" key={`col-${i}`}>
                  <Chip>
                    <ChipText className="mb-0">{brand.name}</ChipText>
                  </Chip>
                </Col>
              ))}
            </Row>
            <Row className="mt-3">
              <Col
                className="d-flex justify-content-end "
                onClick={() =>
                  window.open(
                    `/single-dealer/${dealer.slug}/#opinion-tab`,
                    "_blank"
                  )
                }
              >
                <AllOpinions className="mb-0">
                  Zobacz wszystkie opinie
                </AllOpinions>
                <div>
                  <AllOpinionsArrow className="ml-2"></AllOpinionsArrow>
                </div>
              </Col>
            </Row>
          </div>
        </DealerInfo>
      </Row>
      {dealer.workers.filter((worker) => worker.salon).length !== 0 && (
        <ConsultantsRow>
          <Col className="d-flex">
            <ConsultantsTitle className="mb-0 m-auto">
              Nasi najlepsi doradcy:
            </ConsultantsTitle>
          </Col>
        </ConsultantsRow>
      )}
      {dealer.workers.filter((worker) => worker.salon).length !== 0 && (
        <AllWorkers>
          <div className="container">
            <Row className="justify-content-center">
              {dealer.workers
                .filter((worker) => worker.salon)
                .sort((worker1, worker2) =>
                  worker1.average_rating < worker2.average_rating ? 1 : -1
                )
                .slice(
                  0,
                  windowWidth <= 768
                    ? extendedWorkerView
                      ? 8
                      : 2
                    : extendedWorkerView
                    ? 9
                    : 3
                )
                .map((worker, i) => (
                  <Col
                    className={`col-6 pt-2 col-md-4 d-flex flex-column ${
                      windowWidth <= 768
                        ? i % 2 !== 0
                          ? "border-left"
                          : ""
                        : i % 3 !== 0
                        ? "border-left"
                        : ""
                    }
                    ${extendedWorkerView ? "border-bottom" : ""}
                    `}
                    key={`col-${i}`}
                  >
                    <img
                      style={{ height: "80px" }}
                      className="m-auto"
                      src={
                        worker.avatar
                          ? worker.avatar
                          : "/static/dist/public/assets/global/noimg-worker.jpg"
                      }
                      alt={`Zdjęcie profilowe pracownika: ${worker.first_name} ${worker.last_name}`}
                    ></img>

                    <WorkerName className="mx-auto mt-2 mb-0">
                      {worker.first_name} {worker.last_name}
                    </WorkerName>

                    <div className="d-flex mx-auto mt-3 mb-0">
                      {[...Array(5)].map((_, i) => (
                        <WorkerStar
                          key={`star-${i}`}
                          className="my-auto"
                          src={`/static/dist/public/assets/global/star-${getRate(i, dealer.average_rating)}-10.svg`}
                        ></WorkerStar>
                      ))}
                      <WorkerRating className="mb-0 ml-2">
                        {worker.average_rating}({worker.rating_count})
                      </WorkerRating>
                    </div>
                    <a
                      href={`/single-worker/${worker.slug}`}
                      target="_blank"
                      className="mx-auto mt-1 mb-0"
                    >
                      <WorkerOpinions className="my-auto mr-2">
                        Zobacz profil
                      </WorkerOpinions>
                      <WorkerOpinionsArrow className=" my-auto"></WorkerOpinionsArrow>
                    </a>
                    <Form.Check
                      className="mx-auto mt-2 mb-0"
                      type="checkbox"
                      label="Wyślij maila z zapytaniem"
                      id={`worker-${worker.id}`}
                      bsPrefix="custom-worker-checkbox"
                      disabled={
                        selectedWorkerId !== -1 &&
                        selectedWorkerId !== worker.id
                      }
                      checked={selectedWorkers && selectedWorkers.includes(worker.id)}
                      onChange={() => {
                        selectWorker(worker);
                      }}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </AllWorkers>
      )}
      {dealer.workers.filter((worker) => worker.salon).length > 3 && (
        <ShowMore className="p-0">
          <Col>
            <div
              className="d-flex"
              onClick={() => setExtendedWorkerView(!extendedWorkerView)}
            >
              <ShowMoreWorkers className="mb-0 mr-2 ml-auto">
                {extendedWorkerView ? "Pokaż minej" : "Pokaż więcej"}
              </ShowMoreWorkers>
              <div className="mx-0">
                {extendedWorkerView ? (
                  <ShowLessWorkersArrow className=""></ShowLessWorkersArrow>
                ) : (
                  <ShowMoreWorkersArrow className=""></ShowMoreWorkersArrow>
                )}
              </div>
            </div>
          </Col>
        </ShowMore>
      )}
    </Card>
  );
};

export default DealerCard;
