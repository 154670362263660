import React from "react";
import { Col, Row } from "react-bootstrap";
import { imagePlaceholder } from "src/scripts/enums/defaults";
import { 
    changePeriodFormat, 
    changeSelfDepositFormat, 
    changeMileageLimitFormat 
  } from "src/scripts/misc/dataFromatters";
import { LeasingOffer } from "../../../models/LeasingOffer";
import { BrandName, GrossValue, ModelName, NetValue, Divider, CarImage, OfferDetails, OfferDetailsTitle, OfferDetailsValue, OfferIconsWrapper, Wrapper } from "./Styled";

interface Props {
    leasingOffer: LeasingOffer | undefined;
}

const LeasingOfferSummary = (props: Props) => {

  const { leasingOffer } = props;
  const noImgDealer = window.location.origin + imagePlaceholder;

  return (
    <Wrapper>
      <h1>
        <div className="offer-details--container--header">
          Formularz kontaktowy ws. wynajmu długoterminowego
        </div>
        <Row>
            <Col className="col-6 pr-0">
                <BrandName className="mb-0">{leasingOffer?.car.brand.name}</BrandName>
            </Col>
            <Col className="col-6 pl-1">
                <NetValue className="mb-0">{leasingOffer?.net_installment} zł/mc netto</NetValue>
            </Col>
        </Row>
        <Row>
            <Col>
                <ModelName className="mb-0">{leasingOffer?.car.model}</ModelName>
            </Col>
            <Col>
                <GrossValue className="mb-0"> {leasingOffer?.gross_installment} zł/mc brutto</GrossValue>
            </Col>
        </Row>
      </h1>
        <Row className="mt-3">
            <Col>
                <Divider></Divider>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                <CarImage
                    src={ leasingOffer?.car.avatar ? leasingOffer.car.avatar : noImgDealer }
                    alt="Zdjęcie samochodu" />
            </Col>
        </Row>
        <OfferDetails className="mt-4">
            <Col className="d-flex flex-column">
                <OfferDetailsTitle className="mb-0"> Okres:</OfferDetailsTitle>
                <OfferDetailsValue className="mb-2 mx-auto"> {changePeriodFormat(leasingOffer?.period)}</OfferDetailsValue>
            </Col>
            <Col className="d-flex flex-column">
                <OfferDetailsTitle className="mb-0"> Wpłata ({leasingOffer?.self_deposit}%):</OfferDetailsTitle>
                <OfferDetailsValue className="mb-2 mx-auto"> {changeSelfDepositFormat(leasingOffer?.self_deposit,  leasingOffer?.value)} </OfferDetailsValue>
            </Col>
            <Col className="d-flex flex-column">
                <OfferDetailsTitle className="mb-0"> Limit roczny:</OfferDetailsTitle>
                <OfferDetailsValue className="mb-2 mx-auto"> {changeMileageLimitFormat(leasingOffer?.mileage_limit)} </OfferDetailsValue>
            </Col>
        </OfferDetails>
        <OfferIconsWrapper className="mt-4">
            <Col className="d-flex flex-column" style={{ alignItems: "center" }}>
                <img
                    className="m-auto"
                    src={`/static/dist/public/assets/leasing/tire_${leasingOffer?.tires_included ? 'active' : 'disabled'}.svg`}
                    alt="tire"
                />
                <p className={`"mx-auto mt-2 ${leasingOffer?.tires_included ? "font-weight-bold" : ""}`}> Opony </p>
            </Col>
            <Col className="d-flex flex-column" style={{ alignItems: "center" }}>
                <img
                    className="m-auto"
                    src={`/static/dist/public/assets/leasing/inspection_${leasingOffer?.insurance_included ? 'active' : 'disabled'}.svg`}
                    alt="inspection"
                />
                <p className={`"mx-auto mt-2 ${leasingOffer?.insurance_included ? "font-weight-bold" : ""}`}> Ubezpieczenie </p>
            </Col>
            <Col className="d-flex flex-column" style={{ alignItems: "center" }}>
                <img
                    className="m-auto"
                    src={`/static/dist/public/assets/leasing/trailer_${leasingOffer?.assistance_included ? 'active' : 'disabled'}.svg`}
                    alt="trailer"
                />
                <p className={`"mx-auto mt-2 ${leasingOffer?.assistance_included ? "font-weight-bold" : ""}`}> Assistance </p>
            </Col>
            <Col className="d-flex flex-column" style={{ alignItems: "center" }}>
                <img
                    className="m-auto"
                    src={`/static/dist/public/assets/leasing/mechanic_${leasingOffer?.service_included ? 'active' : 'disabled'}.svg`}
                    alt="mechanic"
                />
                <p className={`"mx-auto mt-2 ${leasingOffer?.service_included ? "font-weight-bold" : ""}`}> Serwis </p>
            </Col>
        </OfferIconsWrapper>
        <Row className="mt-3 d-none d-lg-block">
            <Col>
                <a href={`/finansowanie/wynajem/offer/${leasingOffer?.id}/${document.location.search}`}>
                    <button type="button" className="button-secondary w-100">powrót do oferty</button>
                </a>
            </Col>
        </Row>
  </Wrapper>
  );
};

export default LeasingOfferSummary;
