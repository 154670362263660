export const changePeriodFormat = (period: number | undefined): string => {
  if (period !== undefined) {
    return `${period} mc`;
//     Sebastian requested to have only "mc" everywhere but I keep last code cuz I liked it :)
    /* if (period < 12) {
      return `${period} mc`;
    } else if (period === 12) {
      return `rok`;
    } else if (period % 12 === 0) {
      const years = Math.floor(period / 12);
      if (years === 2 || years === 3 || years === 4) {
        return `${years} lata`;
      } else {
        return `${years} lat`;
      }
    } else {
      const years = Math.floor(period / 12);
      const months = period % 12;
      if (years === 2 || years === 3 || years === 4) {
        return `${years} lata ${months} mc`;
      } else {
        return `${years} lat ${months} mc`;
      }
    } */
  }
  return "";
};

export const changeSelfDepositFormat = (
  selfDeposit: number | undefined, carValue: number | undefined
): string => {
  if (selfDeposit !== undefined && carValue !== undefined) {
    const value = Math.round(carValue * selfDeposit / 100)
    if (value < 10000) {
      return `${value} zł`;
    } else {
      const deposit = Math.round(value / 100) / 10;
      return `${deposit} tys. zł`;
    }
  }
  return "";
};

export const changeCarValueFormat = (
  carValue: number | undefined
): string => {
  if (carValue !== undefined) {
    const roundValue = Math.round(carValue / 1000);
    return `${roundValue} tys. zł`;
  }
  return "";
};

export const changeMileageLimitFormat = (
  mileageLimit: number | undefined
): string => {
  if (mileageLimit !== undefined) {
    if (mileageLimit < 1000) {
      return `${mileageLimit} km`;
    } else {
      const limit = Math.round(mileageLimit / 1000);
      return `${limit} tys. km`;
    }
  }
  return "";
};

export const changeOfferNumberFormat = (
  offerNumber: number | undefined
): string => {
  if (offerNumber !== undefined) {
    if (offerNumber <= 0) {
      return `${offerNumber} ofert`;
    } else if (offerNumber == 1) {
      return `${offerNumber} oferta`;
    } else if (offerNumber <= 4) {
      return `${offerNumber} oferty`;
    } else {
      return `${offerNumber} ofert`;
    }
  }
  return "";
};
