export const installmentMarks = {
  0: "MIN",
  4.54: "",
  9.09: "",
  13.63: "700zł",
  18.18: "",
  22.72: "",
  27.27: "",
  31.81: "1100zł",
  36.36: "",
  40.9: "",
  45.45: "",
  49.99: "1500zł",
  54.54: "",
  59.09: "",
  63.63: "",
  68.18: "1900zł",
  72.72: "",
  77.27: "",
  81.81: "",
  86.36: "2300zł",
  90.9: "",
  95.45: "",
  100: "MAX",
};

export const durationMarks = {
  0: "12mc",
  20: "24mc",
  40: "36mc",
  60: "48mc",
  80: "60mc",
  100: "72mc",
};

export const budgetMarks = {
  0: "0%",
  16.66: "5%",
  33.33: "10%",
  49.99: "15%",
  66.66: "20%",
  83.33: "25%",
  100: "30%",
};

export const mileageMarks = {
  0: "10k",
  16.66: "15k",
  33.33: "20k",
  49.99: "25k",
  66.66: "30k",
  83.33: "35k",
  100: "40k",
};

export const activeDotStyle = {
  borderColor: "#0062aa",
};

export const handleStyle = [
  {
    backgroundColor: "#f6a335",
    borderColor: "#f6a335",
  },
  {
    backgroundColor: "#f6a335",
    borderColor: "#f6a335",
  },
];

export const trackStyle = [
  {
    backgroundColor: "#0062aa",
  },
];

export const NET_INSTALLMENT_MAX_PRICE = 100000;
export const NET_INSTALLMENT_MIN_PRICE = 0;
