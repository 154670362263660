import React from "react";
import { Card, Row, Col, Image, CardImg } from "react-bootstrap";
import { LeasingOffer } from "../../models/LeasingOffer";
import styled from "styled-components";
import {
  changePeriodFormat,
  changeSelfDepositFormat,
  changeMileageLimitFormat,
} from "src/scripts/misc/dataFromatters";
import { imagePlaceholder } from "src/scripts/enums/defaults";

const StyledCard = styled(Card)`
  font-family: Montserrat, serif;
  /* max-height: 400px; */
  border-radius: 15px;
  margin-left: 8px;
  margin-right: 8px;
  width: 390px;
`;
const StyledCardImg = styled(CardImg)`
  border-radius: 15px 15px 0 0;
  height: 250px;
  width: 388px;
  object-fit: cover;
`;
const CardText = styled.p<{
  yellow?: boolean;
  textSize: number;
  textAlign?: "left" | "right" | "center";
  underline?: boolean;
}>`
  font-size: ${(props) => props.textSize}px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
  margin-bottom: 2px;
  color: ${(props) => (props.yellow ? "#f6a335" : "black")};
  text-align: ${(props) => props.textAlign || "left"};
  text-decoration: ${(props) =>
    props.underline ? "underline #0062AA 3px" : "none"};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const OfferCard = (props: { offer: LeasingOffer }) => {
  const { offer } = props;
  const noImgDealer = window.location.origin + imagePlaceholder;
  return (
    <a href={`/finansowanie/wynajem/offer/${offer.id}/${document.location.search}`} >
      <StyledCard className="mt-3">
        <StyledCardImg
          src={offer.car.avatar ? offer.car.avatar : noImgDealer}
          alt="Zdjęcie główne samochodu"
        ></StyledCardImg>
        <Card.Body>
          <Row>
            <Col xs={7} className="pr-0">
              <CardText textSize={22}>{offer.car.brand.name}</CardText>
            </Col>
            <Col xs={5} className="pl-1">
              <strong>
                <CardText textSize={22} yellow textAlign="right">
                  {offer.net_installment} zł/mc
                </CardText>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col xs={9} className="pr-0">
              <CardText textSize={16}>{offer.car.model}</CardText>
            </Col>
            <Col xs={3}>
              <CardText textSize={16} yellow textAlign="right">
                Netto
              </CardText>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CardText textSize={12}>{offer.car.specification}</CardText>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={4}>
              <CardText textSize={12} textAlign="center">
                Okres:
              </CardText>
              <CardText textSize={18} textAlign="center" underline>
                {changePeriodFormat(offer.period)}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText textSize={12} textAlign="center">
                Wpłata ({offer.self_deposit}%):
              </CardText>
              <CardText textSize={18} textAlign="center" underline>
                {changeSelfDepositFormat(offer.self_deposit, offer.value)}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText textSize={12} textAlign="center">
                Limit roczny:
              </CardText>
              <CardText textSize={18} textAlign="center" underline>
                {changeMileageLimitFormat(offer.mileage_limit)}
              </CardText>
            </Col>
          </Row>
        </Card.Body>
      </StyledCard>
    </a>
  );
};
export default OfferCard;
