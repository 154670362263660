import React from "react";
import { Card, Row, Col, Image, CardImg } from "react-bootstrap";
import { AggregatedLeasingOffer } from "../../models/AggregatedLeasingOffer";
import styled from "styled-components";
import {
  changeOfferNumberFormat,
} from "src/scripts/misc/dataFromatters";
import { imagePlaceholder } from "src/scripts/enums/defaults";

const StyledCard = styled(Card)`
  font-family: Montserrat, serif;
  /* max-height: 400px; */
  border-radius: 15px;
  margin-left: 8px;
  margin-right: 8px;
  width: 390px;
`;
const StyledCardImg = styled(CardImg)`
  border-radius: 15px 15px 0 0;
  height: 250px;
  width: 388px;
  object-fit: cover;
`;
const CardText = styled.p<{
  yellow?: boolean;
  textSize: number;
  textAlign?: "left" | "right" | "center";
  underline?: boolean;
}>`
  font-size: ${(props) => props.textSize}px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal;
  margin-bottom: 2px;
  color: ${(props) => (props.yellow ? "#f6a335" : "black")};
  text-align: ${(props) => props.textAlign || "left"};
  text-decoration: ${(props) =>
    props.underline ? "underline #0062AA 3px" : "none"};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const AggregatedOffersCard = (props: { offer: AggregatedLeasingOffer }) => {
  const { offer } = props;
  const noImgDealer = window.location.origin + imagePlaceholder;
  const price_string = offer.number_of_offers === 1 ? `${offer.min_price} zł/mc Netto` : `${offer.min_price} - ${offer.max_price} zł/mc Netto`;
  return (
    <a href={`/finansowanie/wynajem/offer/${offer.offer_id}/${document.location.search}`}>
      <StyledCard className="mt-3">
        <StyledCardImg
          src={offer.avatar ? offer.avatar : noImgDealer}
          alt="Zdjęcie główne samochodu"
        ></StyledCardImg>
        <Card.Body>
          <Row>
            <Col>
              <CardText textSize={22}>{offer.brand.name}</CardText>
            </Col>
          </Row>
          <Row>
            <Col>
              <CardText textSize={16}>{offer.model}</CardText>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CardText textSize={12}>{offer.specification}</CardText>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <CardText textSize={22} yellow textAlign="center">
                {price_string}
              </CardText>
            </Col>
          </Row>
          <Row>
            <Col>
              <CardText textSize={16} textAlign="center" underline>
                {changeOfferNumberFormat(offer.number_of_offers)}
              </CardText>
            </Col>
          </Row>
        </Card.Body>
      </StyledCard>
    </a>
  );
};
export default AggregatedOffersCard;
