import axios from "axios";
import { get } from "lodash-es";
const axiosInstance = axios.create({
  headers: {},
  maxRedirects: 0,
  baseURL: process.env.API_URL,
});
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    return handleResponseError(error);
  }
);
const handleResponseError = (request: any) => {
  const status = get(request, "response.status");
  switch (status) {
    case 500:
    case undefined:
      console.log("Unknown error");
      break;
    case 400:
      console.log("Bad request");
      break;
    case 401:
    case 403:
      console.log("No access");
      break;
    case 404:
      console.log("Site does not exist");
      window.location.href = `/404.html`;
      break;
    default:
      break;
  }
  return Promise.reject(request);
};
export default axiosInstance;