import React, { useEffect, useState } from "react";
import DropdownSelect from "src/scripts/components/common/DropdownSelect";
import { fetchLeasingCarBrands } from "src/scripts/services/leasing.resource";
import ReactDOM from "react-dom";
import { Button, Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";
import { Voivodeship } from "../../models/Voivodeship";
import { VoivodeshipsList } from "../../static-data/Voivodeships";

export interface Brand {
  id: number;
  name: string;
  icon: string;
  counter: number;
}

const StyledButton = styled(Button)`
  padding-left: 25%;
  padding-right: 25%;
  height: 50px;
  &:hover {
    color: #fff;
    background-color: #f6a335;;
    border-color: #fff;
  }
`;

const StyledH2 = styled.h2`
  font: normal normal normal 12px/15px Montserrat, serif;
  letter-spacing: 0;
  color: #ffff;
  opacity: 1;
  margin: auto;

  @media screen and (min-width: 768px) {
    font: normal normal normal 18px/34px Montserrat, serif;
  }
`;

const StyledColumn = styled(Col)`
  background: #404040 0 0 no-repeat padding-box;
  opacity: 1;
  cursor: pointer;
  height: 54px;
`;

const StyledColumnLeft = styled(StyledColumn)`
  border-top-left-radius: 10px;
`;

const StyledColumnRight = styled(StyledColumn)`
  border-top-right-radius: 10px;
`;

const CustomCheckBoxInput = styled(Form.Check.Input)`
  width: 23px;
  height: 23px;
  border: 2px solid #404040;
  opacity: 1;
`;

const CustomCheckBoxLabel = styled(Form.Check.Label)`
  text-align: left;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    font: normal normal normal 18px/28px Montserrat;
  }
`;

const SearchOpinionsForm = () => {
  const [voivodeships, setVoivodeships] = useState<Voivodeship[]>(VoivodeshipsList);
  const [selectedVoivodeships, setSelectedVoivodeships] = useState<Voivodeship[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [onlyVerified, setOnlyVerified] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('salon');

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchLeasingCarBrands();
      if (response.data.results) {
        setBrands(response.data.results);
      }
    };
    fetchData();
    if (window.location.href.search('service') !== -1) {
      setSelectedTab('service');
    }
  }, []);


  const search = () => {
    window.location.href = `/search/${selectedTab}/` + generateSearchQuerry();
  }

  const generateSearchQuerry = () => {

    const dealerVerified = onlyVerified ? "certified=true" : "";
    const voivodeshipsQuerry =
      selectedVoivodeships.length !== 0
        ? `voivodeship=${selectedVoivodeships.map(value => value.name).join(",")}&`
        : "";
    const brandsQuerry =
        selectedBrands.length !== 0 && selectedBrands.length !== 1
        ? `brand=${selectedBrands.map(value => value.name).join(",")}&`
        : "";
    const singleBrand = selectedBrands.length === 1 ? `${selectedBrands[0].name}/` : "";

    return `${singleBrand}?${brandsQuerry}${voivodeshipsQuerry}${dealerVerified}`;
  };

  const modifyUrl = (type: string) => {
    const newurl = window.location.href.replace(/service|salon/g, type)
    window.history.pushState({path:newurl},'',newurl);
  }

  const switchType = (type: string) => {
    setSelectedTab(type); 
    modifyUrl(type)
  }

  return (
    <div className="container">
      <Row style={{margin: 0}}>
        <StyledColumnLeft 
          className="d-flex justify-content-center" 
          style={{background: selectedTab === 'salon' ? '#FFFFFF 0 0 no-repeat padding-box' : '#404040 0 0 no-repeat padding-box'}}
          onClick={() => switchType('salon')}>
          <StyledH2
            style={{color: selectedTab === 'salon' ?  '#404040' : '#FFFFFF'}}
            >
            Opinie salonów
          </StyledH2>
        </StyledColumnLeft>
        <StyledColumnRight 
          className="d-flex justify-content-center"
          style={{background: selectedTab === 'service' ? '#FFFFFF 0 0 no-repeat padding-box' : '#404040 0 0 no-repeat padding-box'}}
          onClick={() => switchType('service')}>
          <StyledH2
            style={{color: selectedTab === 'service' ? '#404040' : '#FFFFFF'}}
            >
            Opinie serwisów
          </StyledH2>
        </StyledColumnRight>
      </Row>
      <Row className="d-flex mx-4">
        <Col className="d-flex justify-content-center mt-3">
          <DropdownSelect
            options={brands}
            selected={selectedBrands}
            setSelected={setSelectedBrands}
            placeholder="Marka"
          />
        </Col>
        <Col className="d-flex justify-content-center mt-3">
          <DropdownSelect
            options={voivodeships}
            selected={selectedVoivodeships}
            setSelected={setSelectedVoivodeships}
            placeholder="Województwo"
          />
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <StyledButton
          variant="primary"
          className="py-2 d-flex align-items-center button-primary"
          onClick={() => search()}>
          <span>Szukaj dealera</span>
        </StyledButton>
      </Row>
      <Row className="mt-4 ml-5">
          <Form.Check bsPrefix="custom-dropdown-checkbox" type="checkbox" label="Tylko certyfikowani dealerzy">
            <CustomCheckBoxInput type="checkbox" checked={onlyVerified} onChange={() => setOnlyVerified(!onlyVerified)} />
            <CustomCheckBoxLabel className="ml-2 mt-1" onClick={() => setOnlyVerified(!onlyVerified)}>
              Tylko certyfikowani dealerzy
            </CustomCheckBoxLabel>
          </Form.Check>

      </Row>
    </div>
  );
};
const opinionsHomepageFormRoot = document.getElementById(
  "react--opinions-homepage-form"
);
if (opinionsHomepageFormRoot) {
  ReactDOM.render(<SearchOpinionsForm />, opinionsHomepageFormRoot);
}
