import React, { useEffect, useState, useCallback, createRef } from "react";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import * as Scroll from "react-scroll";
import {
  fetchLeasingOfferDealers,
  fetchLeasingOfferDetails,
  postUserLeasingContactForm,
} from "src/scripts/services/leasing.resource";
import DropdownOptions from "../common/DropdownOptions";
import DropdownSelect from "../common/DropdownSelect";
import DealerCard from "../DealerCard/DealerCard";
import LeasingOfferSummary from "./LeasingOfferSummary/LeasingOfferSummary";
import { Dealer } from "../../models/Dealer";
import { Voivodeship } from "../../models/Voivodeship";
import { FilterOptions, SelectItem } from "../../static-data/FilterOptions";
import { VoivodeshipsList } from "../../static-data/Voivodeships";
import {
  CustomInput,
  CustomCheck,
  CustomCheckBoxInput,
  CustomCheckBoxLabel,
  CustomTextAreaInput,
  Description,
  CustomButton,
  Extend,
  SortTitle,
  SortDescription,
  FilterRow,
  FormWrapper,
} from "./Styled";
import { LeasingOffer } from "../../models/LeasingOffer";
import ErrorModal from "../common/ErrorModal";
import ReactDOM from "react-dom";
// import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { carBodiesUnselected } from "src/scripts/enums/car-bodies";
import Loader from "../common/Loader";

const LeasingOfferContactForm = () => {
  const [leasingOffer, setLeasingOffer] = useState<LeasingOffer>();

  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [dealersFirstApiCallFinished, setDealersFirstApiCallFinished] = useState<boolean>(false);
  const [companyPurchase, setCompanyPurchase] = useState<boolean>(true);
  const [extendedDisclaimer, setExtendedDisclaimer] = useState<boolean>(false);
  const [nameForm, setNameForm] = useState<string>("");
  const [surnameForm, setSurnameForm] = useState<string>("");
  const [emailForm, setEmailForm] = useState<string>("");
  const [phoneNumberForm, setPhoneNumberForm] = useState<string>("");
  const [nipForm, setNipForm] = useState<string>("");
  const [messageForm, setMessageForm] = useState<string>("");

  const [isNameValid, setIsNameValid] = useState<boolean>(true);
  const [isSurnameValid, setIsSurnameValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
  const [isNipValid, setIsNipValid] = useState<boolean>(true);

  const [validated, setValidated] = useState(false);
  const [formCorrect, setFormCorrect] = useState(false);

//   const [showErrorModal, setShowErrorModal] = useState(false);
//   const [msgErrorModal, setMsgErrorModal] = useState<string>("");
  const [selectedDealers, setSelectedDealers] = useState<number[]>();
  const [selectedWorkers, setSelectedWorkers] = useState<number[]>();

  const [voivodeships, setVoivodeships] =
    useState<Voivodeship[]>(VoivodeshipsList);
  const [selectedVoivodeships, setSelectedVoivodeships] = useState<
    Voivodeship[]
  >([]);

  const [filterOptions, setFilterOptions] =
    useState<SelectItem[]>(FilterOptions);
  const [selectedFilter, setSelectedFilter] = useState<SelectItem>(
    FilterOptions[0]
  );

  const [statementCheckbox, setStatementCheckbox] = useState<boolean>(false);

  const [loadingDealers, setLoadingDealers] = useState(false);
//   const [token, setToken] = useState<string | null>(null);
//   const recaptcha = createRef<ReCaptcha>();

  const buttonRef = createRef<HTMLButtonElement>();

//   const verifyCallback = (recaptchaToken: string) => {
//     setToken(recaptchaToken);
//   };

  const capitalizeFirstLetter = (
    event: React.FormEvent<HTMLInputElement>
  ): string => {
    return event.currentTarget.value.length > 0
      ? `${event.currentTarget.value[0].toUpperCase()}${event.currentTarget.value.substring(
          1
        )}`
      : event.currentTarget.value;
  };

  const validateEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setIsEmailValid(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        event.currentTarget.value
      )
    );
  };

  const validatePhoneNumber = (event: React.FormEvent<HTMLInputElement>) => {
    setIsPhoneNumberValid(/^(\+\d{2})?\d{9}$/.test(event.currentTarget.value));
  };

  const validateNip = (event: React.FormEvent<HTMLInputElement>) => {
    setIsNipValid(/^[0-9]{10}$/.test(event.currentTarget.value));
  };

  const fetchDealerData = async (brand: string) => {
    const url = new URL(window.location.href);
    const pk = parseInt(url.pathname.split("/")[4]);
    const apiSearchParams = {
      salon: true,
      car_brands__name__in: brand,
      voivodeship__in: selectedVoivodeships.map((value) => value.id).join(","),
      ordering: selectedFilter.value,
    };

    const response = await fetchLeasingOfferDealers(apiSearchParams);
    if (response.data.results) {
      const paidVerified = response.data.results.filter(
        (dealer) => dealer.paid === true && dealer.verified === true && dealer.manager !== null
      );
      setDealers(paidVerified)
      // if (paidVerified.length !== 0) {
      //   setDealers(paidVerified)
      // } else {
      //   setDealers(response.data.results);
      // }

      setLoadingDealers(false);
    }
    if (!dealersFirstApiCallFinished) {
      setDealersFirstApiCallFinished(true)
    }
  };
//   useEffect(() => {
//     updateToken();
//   }, [showErrorModal]);

  useEffect(() => {
    const fetchData = async () => {
      const url = new URL(window.location.href);
      const pk = parseInt(url.pathname.split("/")[4]);
      const response = await fetchLeasingOfferDetails(pk);
      if (response.data) {
        setLeasingOffer(response.data);
        if (response.data.car) {
          fetchDealerData(response.data.car.brand.name);
        }
      }
    };
//     loadReCaptcha(captchaKey);
    fetchData();
  }, []);

  useEffect(() => {
    if (leasingOffer) {
      setLoadingDealers(true);
      fetchDealerData(leasingOffer.car.brand.name);
    }
  }, [selectedVoivodeships, selectedFilter]);

  const validateForm = (event: any) => {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      setFormCorrect(true);
      if (!dealersFirstApiCallFinished) {
        setLoadingDealers(true);
      }
      Scroll.animateScroll.scrollTo(750);
    }
    else
    {
      Scroll.animateScroll.scrollTo(0);
    }
  };

//   const updateToken = () => {
//     if (recaptcha.current !== null) {
//       recaptcha.current.execute();
//     }
//   };

  const handleSubmit = () => {
    const url = new URL(window.location.href);
    const pk = parseInt(url.pathname.split("/")[4]);
    const data = {
      name: nameForm,
      surname: surnameForm,
      email_address: emailForm,
      phone_number: phoneNumberForm,
      nip: nipForm,
      purchase_type: companyPurchase ? 2 : 1,
      message: messageForm,
      offer_pk: pk,
      dealers: selectedDealers,
      workers: selectedWorkers,
//       recaptcha: token,
      voivodeships: selectedVoivodeships ? selectedVoivodeships : null,
    };
    const postData = async () => {
      const response = await postUserLeasingContactForm(data).catch((_) => {
        setLoadingDealers(false);
//         setMsgErrorModal(_.response.data.recaptcha);
//         setShowErrorModal(true);
      });
      if (response && response.status === 200) {
        if (!data.dealers) {
          window.location.href = `/finansowanie/wynajem/thanks/?goto=1`; // lead goes to us
        }
        else
        {
          window.location.href = `/finansowanie/wynajem/thanks/?goto=2`; // lead goes to dealer
        }
        // TODO wersja dla importera
      }
    };
    setLoadingDealers(true);
    postData();
  };

  return (
    <div className="mt-4 mt-lg-0 position-relative">
      <Loader visible={loadingDealers}></Loader>
      <Form noValidate validated={validated} onSubmit={validateForm}>
        <Row className="justify-content-center">
          <Col className="col order-2 order-lg-1 mt-3 mt-lg-0">
            <FormWrapper>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Form.Group className="w-100">
                    <CustomInput
                      type="text"
                      required
                      placeholder="Imię"
                      pattern="[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżVvXxQq ]+"
                      value={nameForm}
                      isInvalid={!isNameValid}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setNameForm(capitalizeFirstLetter(e))
                      }
                      onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                        setIsNameValid(
                          /^([^0-9]*)$/.test(e.currentTarget.value)
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Imię nie jest poprawne.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="d-flex justify-content-center mt-2 mt-sm-0 mt-lg-2 mt-xl-0">
                  <Form.Group className="w-100">
                    <CustomInput
                      type="text"
                      required
                      placeholder="Nazwisko"
                      pattern="[AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżVvXxQq ]+"
                      value={surnameForm}
                      isInvalid={!isSurnameValid}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setSurnameForm(capitalizeFirstLetter(e))
                      }
                      onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                        setIsSurnameValid(
                          /^([^0-9]*)$/.test(e.currentTarget.value)
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Nazwisko nie jest poprawne.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-center">
                  <Form.Group className="w-100">
                    <CustomInput
                      type="email"
                      required
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      placeholder="Email"
                      value={emailForm}
                      isInvalid={!isEmailValid}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setEmailForm(e.currentTarget.value)
                      }
                      onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                        validateEmail(e)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Adres email jest nie poprawny.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="d-flex justify-content-center mt-2 mt-sm-0 mt-lg-2 mt-xl-0">
                  <Form.Group className="w-100">
                    <CustomInput
                      type="tel"
                      required
                      pattern="^(\+\d{2})?\d{9}$"
                      placeholder="Nr telefonu"
                      value={phoneNumberForm}
                      isInvalid={!isPhoneNumberValid}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setPhoneNumberForm(e.currentTarget.value)
                      }
                      onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                        validatePhoneNumber(e)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Nr telefonu jest nie poprawny.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-center flex-column">
                  <CustomCheck type="radio" name="type" label="Zakup na firmę">
                    <CustomCheckBoxInput
                      type="radio"
                      checked={companyPurchase}
                      onChange={() => setCompanyPurchase(true)}
                    />
                    <CustomCheckBoxLabel
                      className="ml-2 mt-1"
                      onClick={() => setCompanyPurchase(true)}
                    >
                      Zakup na firmę
                    </CustomCheckBoxLabel>
                  </CustomCheck>
                  <CustomCheck type="radio" name="type" label="Zakup prywatnie">
                    <CustomCheckBoxInput
                      type="radio"
                      checked={!companyPurchase}
                      onChange={() => setCompanyPurchase(false)}
                    />
                    <CustomCheckBoxLabel
                      className="ml-2 mt-1"
                      onClick={() => setCompanyPurchase(false)}
                    >
                      Zakup prywatnie
                    </CustomCheckBoxLabel>
                  </CustomCheck>
                </Col>
                <Col className="d-flex justify-content-center mt-2 mt-sm-0">
                  {companyPurchase && (
                    <Form.Group className="w-100">
                      <CustomInput
                        required
                        type="numbers"
                        pattern="^[0-9]{10}$"
                        placeholder="Nip"
                        value={nipForm}
                        isInvalid={!isNipValid}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          setNipForm(e.currentTarget.value)
                        }
                        onBlur={(e: React.FormEvent<HTMLInputElement>) =>
                          validateNip(e)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Nip jest nie poprawny.
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <CustomTextAreaInput
                    as="textarea"
                    placeholder="Wiadomość (opcjonalnie)"
                    onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                      setMessageForm(e.currentTarget.value)
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div>
                    <Form.Check type="checkbox" label="Disclaimer">
                      <CustomCheckBoxInput
                        onChange={() =>
                          setStatementCheckbox(!statementCheckbox)
                        }
                        checked={statementCheckbox}
                        required
                        type="checkbox"
                      />
                      <Description className="ml-2 mt-1">
                        <a
                          onClick={() =>
                            setStatementCheckbox(!statementCheckbox)
                          }
                        >
                          Oświadczam, że zapoznałem się z treścią
                        </a>{" "}
                        <a href="/terms/" target="_blank">
                          Regulaminu Serwisu
                        </a>{" "}
                        <a
                          onClick={() =>
                            setStatementCheckbox(!statementCheckbox)
                          }
                        >
                          oraz stanowiącymi ich integralną część
                        </a>{" "}
                        <a href="/privacy/" target="_blank">
                          Polityką prywatności Serwisu
                        </a>{" "}
                        <a
                          onClick={() =>
                            setStatementCheckbox(!statementCheckbox)
                          }
                        >
                          oraz
                        </a>{" "}
                        <a href="/cookies/" target="_blank">
                          Polityką Plików Cookies
                        </a>{" "}
                        <a
                          onClick={() =>
                            setStatementCheckbox(!statementCheckbox)
                          }
                        >
                          i akceptuję ich treść. Wyrażam zgodę na udostępnienie
                          moich danych z formularza wybranym Dealerom i/lub
                          importerom wygranej marki samochodu w celu nawiązania
                          ze mną kontaktu na temat oferty handlowej oferowanych
                          samochodów.
                        </a>
                        {extendedDisclaimer && (
                          <a
                            onClick={() =>
                              setStatementCheckbox(!statementCheckbox)
                            }
                          >
                            {" "}
                            Oświadczam, że mam świadomość, iż wraz z wysłaniem
                            formularza moje dane osobowe z formularza
                            przekazywane są przez Poznajdealera sp. z o.o
                            wskazanemu Dealerowi i/lub importerowi wygranej
                            marki samochodu w celu nawiązania ze mną kontaktu na
                            temat oferty handlowej oferowanych samochodów, w
                            wybrany przeze mnie sposób, drogą elektroniczną na
                            wskazany e-mail lub na wskazany numer telefonu.
                            Jeżeli wybranie Dealera nie będzie możliwe, to wyrażam
                            zgodę na przekazanie przez Poznajdealera sp. z o.o informacji
                            zawartych w formularzu wybranemu przez Poznajdealera sp. z o.o
                            Dealerowi i/lub importerowi wygranej marki samochodu.
                            Wyrażenie zgody jest dobrowolne, ale niezbędne dla
                            realizacji usługi. Mogę ją w każdym momencie
                            wycofać, bez wpływu na legalność uprzedniego
                            przetwarzania. Wraz z momentem przekazania danych
                            Dealer i/lub importer staje się odrębnym
                            administratorem moich danych osobowych,
                            odpowiedzialnym za ich przetwarzanie.
                            <Extend
                              onClick={() => setExtendedDisclaimer(false)}
                            >
                              {" "}
                              Zwiń
                            </Extend>
                          </a>
                        )}
                        {!extendedDisclaimer && (
                          <Extend onClick={() => setExtendedDisclaimer(true)}>
                            {" "}
                            Rozwiń
                          </Extend>
                        )}
                      </Description>
                      <Form.Control.Feedback type="invalid">
                        Proszę zaznaczyć oświadczenie.
                      </Form.Control.Feedback>
                    </Form.Check>
                  </div>
                </Col>
              </Row>
            </FormWrapper>
          </Col>
          <Col className="col-auto order-1 order-lg-2">
            <LeasingOfferSummary leasingOffer={leasingOffer} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex justify-content-center">
            <CustomButton
              className="button-primary"
              type="submit"
              $valid={formCorrect}
              ref={buttonRef}
              onClick={() => buttonRef.current?.blur()}
            >
              Wybierz dealera
            </CustomButton>
          </Col>
        </Row>
      </Form>
      {formCorrect && (
        <FilterRow className="mt-4">
          <Col className="col-12 col-lg-auto d-none d-lg-flex order-lg-1">
            <SortTitle className="my-auto">Sortuj:</SortTitle>
          </Col>
          <Col className="col-12 col-lg-auto d-flex order-lg-2 order-2 mt-2 mt-lg-0 justify-content-center">
            <DropdownOptions
              open
              options={filterOptions}
              selectedOption={selectedFilter}
              setSelectedOption={setSelectedFilter}
            ></DropdownOptions>
          </Col>
          <Col className="col-12 col-lg-auto d-flex order-lg-3 order-3 mt-2 mt-lg-0 justify-content-center">
            <DropdownSelect
              options={voivodeships}
              selected={selectedVoivodeships}
              setSelected={setSelectedVoivodeships}
              placeholder="Województwo"
            />
          </Col>
          <Col className="col-12 col-lg d-flex order-lg-4 order-1 mt-2 mt-lg-0">
            <SortDescription className="my-auto text-break text-lg-left text-center">
              Możesz wybrać dowolną liczbę salonów, do których zostanie wysłane
              zapytanie ofertowe oraz jednego doradcę w danym salonie.
            </SortDescription>
          </Col>
        </FilterRow>
      )}
      {formCorrect && (
        <div className="container" style={{ minHeight: "400px" }}>
          {dealers.map((dealer, i) => (
            <Row className="mt-4" key={`row-${i}`}>
              <Col>
                <DealerCard
                  dealer={dealer}
                  selectedDealers={selectedDealers}
                  setSelectedDealers={setSelectedDealers}
                  selectedWorkers={selectedWorkers}
                  setSelectedWorkers={setSelectedWorkers}
                ></DealerCard>
              </Col>
            </Row>
          ))}
          {dealers.length === 0 && (
            <div className="d-flex justify-content-center align-items-center flex-column py-5">
              <img
                className="my-2"
                style={{ height: "50px", opacity: "0.3" }}
                src={carBodiesUnselected.coupe.image}
              />
              <h5>Brak dealerów spełniających dane kryteria</h5>
              <h4 className="my-2" style={{ textAlign: "center" }}>Nie szkodzi! Kliknij "OK" i wyślij zapytanie do nas, a my przekażemy informacje w odpowiednie ręce.</h4>
              <CustomButton
                  onClick={() => handleSubmit()}
                  className="mt-3 w-30 button-primary mx-auto"
                >
                  OK
                </CustomButton>
            </div>
          )}
          {dealers.length !== 0 && (
            <Row className="mt-3">
              <Col
                className="d-flex justify-content-center bg-light p-2"
                style={{
                  position: "fixed",
                  bottom: "20px",
                  left: "0",
                  right: "0",
                }}
              >
                <CustomButton
                  disabled={
                    !((selectedDealers &&
                      selectedDealers.length !== 0 ||
                      selectedWorkers &&
                      selectedWorkers.length !== 0) &&
                    formCorrect)
                  }
                  onClick={() => handleSubmit()}
                  className="w-80 button-primary mx-auto"
                >
                  Wyślij wiadomość (
                  {((selectedDealers && selectedDealers.length) ||
                    0) + ((selectedWorkers && selectedWorkers.length) || 0)}
                  )
                </CustomButton>
              </Col>
            </Row>
          )}
        </div>
      )}
      {/*
      <ErrorModal
        show={showErrorModal}
        setShow={setShowErrorModal}
        errorMsg={msgErrorModal}
      ></ErrorModal>
      <ReCaptcha
        ref={recaptcha}
        sitekey={captchaKey}
        action="contact"
        verifyCallback={verifyCallback}
      />
      */}
    </div>
  );
};

const leasingOfferContactForm = document.getElementById(
  "react--leasing-offer-contact-form"
);
if (leasingOfferContactForm) {
  ReactDOM.render(<LeasingOfferContactForm />, leasingOfferContactForm);
}
