import styled from "styled-components";
import { Button, Form, Row } from "react-bootstrap";

export const BrandName = styled.p`
    text-align: left;
    font: normal normal 500 22px/30px Montserrat, serif;
    letter-spacing: 0;
    color: #404040;
    opacity: 1;
`;

export const NetValue = styled.p`
    text-align: right;
    font: normal normal bold 22px/30px Montserrat, serif;
    letter-spacing: 0;
    color: #f6a335;
`;

export const ModelName = styled.p`
    text-align: left;
    font: normal normal 500 16px/30px Montserrat, serif;
    letter-spacing: 0;
    color: black;
    opacity: 1;
`;

export const GrossValue = styled.p`
    text-align: right;
    font: normal normal 500 16px/30px Montserrat, serif;
    letter-spacing: 0;
    color: #f6a335;
`;

export const Divider = styled.div`
    height: 2px;
    background: #0000001a;
`;

export const CarImage = styled.img`
    height: 179px;
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
`;

export const OfferDetails = styled(Row)`
    background: #f3f3f3;
    border-radius: 15px;

    @media screen and (min-width: 992px) {
      background: inherit;
    }
`;

export const OfferDetailsTitle = styled.p`
    text-align: center;
    font: normal normal 300 12px/20px Montserrat, serif;
    letter-spacing: 0;
    color: #404040;
`;

export const OfferDetailsValue = styled.p`
    text-align: center;
    font: normal normal 500 18px/25px Montserrat, serif;
    letter-spacing: 0;
    color: #404040;
    border-bottom: 1px solid #0062aa;
    opacity: 1;
`;

export const OfferIconsWrapper = styled(Row)`
    font: normal normal 500 12px/9px Montserrat, serif;
    letter-spacing: 0;
    color: #404040;
`;

export const Wrapper = styled.div`
    width: 400px;
`